import React, { useState } from "react";
import {SNP_STATUS} from "../Constants";
//import ReactSchemaForm from "./geneEditor_v42/ReactSchemaForm";

const isOwner = false; // TODO DPB support edit and save of gene interpretations

// Match columns / cells the way they are presented

const snpTableHeaderKeys = [
    {"heading": "Group", "className": "gene-table-head-center"},
    {"heading": "Gene Name", "className": "gene-table-head-center"},
    {"heading": "Gene Function", "className": "gene-table-head-left", "colspan": 4},
    {"heading": "Gene Risk (Protection)", "className": "gene-table-head-left"}
]
const snpTableHeaderVariantKeys = [
    {"heading": "", "className": "gene-table-head-center"},
    {"heading": "", "className": "gene-table-head-center"},
    {"heading": "Variant Name", "className": "gene-table-head-center"},
    {"heading": "rsId", "className": "gene-table-head-center"},
    /* {"heading": "Typical Allele", "className": "gene-table-head-center"},*/
    {"heading": "My Genotype", "className": "gene-table-head-center", "colspan": 2},
    {"heading": "Allele", "className": "gene-table-head-left"}
]

function AnnotatedSNPsTable({selectedFilteredAndSortedSNPs, showActions, categoryInfos}) {

    // TODO DPB drill down UX... const [showDetailsSNP, setShowDetailsSNP] = useState(snpListAnnotated[0])

    const highlightSnpGroupWithMatch = (text, selectors) => {
        if (typeof text !== 'string' && !(text instanceof String)) {
            return text;
        }
        return selectors.reduce((textSoFar, selector) => { return selector.highlightSnpGroup(textSoFar, "<span class='gibble'>$1</span>") }, text);
    }

    const highlightSnpNameStartsWithMatch = (text, selectors) => {
        if (!text || (typeof text !== 'string' && !(text instanceof String))) {
            return text;
        }
        return selectors.reduce((textSoFar, selector) => { return selector.highlightSnpName(textSoFar, "<span class='snibble'>$1</span>") }, text);
    }

    const highlightTextMatches = (text, selectors) => {
        if (!Array.isArray(text) && typeof text !== 'string' && !(text instanceof String)) {
            return text;
        }
        return selectors.reduce((textSoFar, selector) => { return selector.highlightText(textSoFar, "<span class='tibble'>$1</span>") }, text);
    }

    // TODO DPB !! IMPORTANT !! if ever displaying user-entered SNP Interpretations, then scrub out HTML tags, etc., to avoid CSS security risk

    function iconForStatus(myStatus) {
        return (typeof myStatus === 'undefined') ? "--" :
            (myStatus.icon || "!");
    }

    function hasVariantInterpretation(rsIdInfo) {
        const rsIdInterpretation = rsIdInfo.rsIdInterpretation;
        const functions = rsIdInterpretation && rsIdInterpretation["variant_functions"];
        const risks = rsIdInterpretation && rsIdInterpretation["variant_health_risks"];
        return (functions && functions.length > 0) || (risks && risks.length > 0);
    }

    function hasAlleleInterpretation(allele) {
        const alleleInterpretation = allele.risk_allele_interpretation;
        const functions = alleleInterpretation?.functions;
        const risks = alleleInterpretation?.health_risks;
        const boons = alleleInterpretation?.health_boons;
        return (functions && functions.length > 0) || (risks && risks.length > 0) || (boons && boons.length > 0);
    }

    function forDisplay(status) {
        return <span className={"genome-status-icon " + (status?.cssClass || "")}>{iconForStatus(status)}</span>;
    }

    const [formData, setFormData] = useState(null);
    const [formErrors, setFormErrors] = useState([]);

    const setShowEditGeneInterpretation = (geneInterpretation) => {
        setFormData(geneInterpretation);
    }

    const handleSubmit = (updatedFormData) => {
        console.log('Updated form data:', updatedFormData);
        // Handle the updated data, e.g., save it to a database or perform other actions
        setFormData(updatedFormData);
    };

    const handleError = (errors) => {
        console.error('Form errors:', errors);
    };


    /*
    Replace `initialFormData` with the provided JSON data for the
        gene_interpretation data structure. This will create a form
        using React JSON Schema Form to edit a gene_interpretation.
        It accepts the initial form data as a prop, shows error lists, includes
        validators and error handling, and has editable fields with labels, prompts,
        and tooltips. List-valued fields have buttons to add, remove, edit, and view items.
        Items can be moved up and down using the `orderable` option in the `ui:options`.
        The `allele` and `typical_allele` fields are restricted to the specified values,
        and the `gene_name` field does not allow spaces.
     */


    return (!Array.isArray(selectedFilteredAndSortedSNPs) || selectedFilteredAndSortedSNPs.length === 0 ?
        "No data to display" :
        <span>
            {isOwner ? <div><h1>Edit Gene Interpretation</h1>
            {(formData) ?
                <span/>
            /*<ReactSchemaForm formData={formData}
                             setData={setFormData}
                             //onError={handleError}
                             setErrors={setFormErrors}
            />*/ : ""}
            <h1>Gene Table</h1></div> : <span/>}
                <div key={"div1"} style={{display: "inline-block", verticalAlign: "middle", marginRight: "1.5em"}}>My Status Legend: </div>
                <div key={"div2"} style={{display: "inline-block", verticalAlign: "middle"}}>
                    <div key={"div3"}>
                        {forDisplay(SNP_STATUS.HOMOZYGOUS_RISK)} homozygous risk; {forDisplay(SNP_STATUS.HETEROZYGOUS_RISK)} heterozygous risk; {forDisplay(SNP_STATUS.WILD)} wild; {forDisplay(SNP_STATUS.HETEROZYGOUS_BOON)} heterozygous protective; {forDisplay(SNP_STATUS.HOMOZYGOUS_BOON)} homozygous protective;
                    </div><div key={"div4"}>
                        {forDisplay(SNP_STATUS.NOT_GENOTYPED)} not genotyped; {forDisplay(SNP_STATUS.FOREIGN_GENOTYPE)} unusual genotype; {forDisplay(SNP_STATUS.NO_INFO)} no interpretation; {forDisplay(SNP_STATUS.WAITING)} waiting for your data
                    </div>
                </div>
            <p/>
          <table id="snp-search-table-id" className={"snp-table"} width="100%">
            <thead key={"snp-table-head"}>
            <tr key={"snp-table-header-row1"} className={"gene-table-header-upper-row"}>
                {snpTableHeaderKeys.map(({heading, className, colspan}, hIdx) => (
                    <th key={"h1-" + hIdx} colSpan={colspan ? colspan : 1} className={className}>{heading}</th>
                ))}
            </tr>
            <tr key={"snp-table-header-separator"} className={"gene-table-header-middle-row"}>
                <th colSpan={2} className={"gene-table-header-middle-row-cell"}></th>
                <th colSpan={snpTableHeaderVariantKeys.length} className={"gene-table-header-middle-row-cell gene-table-header-middle-row-cell-line"}></th>
            </tr>
            <tr key={"snp-table-header-row2"} className={"gene-table-header-lower-row"}>
                {snpTableHeaderVariantKeys.map(({heading, className, colspan}, idx) => (
                    <th key={"h2-" + heading + "-" + idx} colSpan={colspan ? colspan : 1} className={className}>{heading}</th>
                ))}
            </tr>
            </thead>

            <tbody key={"snp-table-body"}>
            {
                selectedFilteredAndSortedSNPs.map((snpInterpretation, nthSNP) => {
                    let bySelectors = snpInterpretation["bySelectors"];
                    if (bySelectors) {
                        // handle case of selections/filter/sort
                        snpInterpretation = snpInterpretation.snpInterpretation;
                    } else {
                        bySelectors = []
                    }
                    const geneName = highlightSnpNameStartsWithMatch(snpInterpretation["gene_name"] || snpInterpretation["snp_name"], bySelectors)
                    const geneNameContextualDiscriminator = snpInterpretation["gene_name_discriminator"]
                    const geneNameSupplement = snpInterpretation["gene_name_supplement"] || snpInterpretation["snp_name_supplement"]
                    const geneNameAKA = highlightSnpNameStartsWithMatch(snpInterpretation["gene_name_aka"], bySelectors)
                    const geneNameAKASupplement = snpInterpretation["gene_name_aka_supplement"] || ""
                    const geneGroup = snpInterpretation["category_rml"] || snpInterpretation["group"];
                    const geneGroupHtml = highlightSnpGroupWithMatch(geneGroup, bySelectors)
                    const geneSupplementAndAKA = [...(geneNameSupplement ? [geneNameSupplement] : []), ...(geneNameAKA ? ["AKA " + geneNameAKA + " " + geneNameAKASupplement] : [])]
                    const geneFunctions = highlightTextMatches(snpInterpretation["gene_functions"] || snpInterpretation["snp_functions"], bySelectors)  // TODO DPB should functions/risks/tests be SNP level and variant level?
                    const geneHealthRisks = highlightTextMatches(snpInterpretation["gene_health_risks"] || snpInterpretation["snp_health_risks"], bySelectors) // TODO phase out snp_health_risks
                    const rsIdInfos = snpInterpretation["rsId_infos"]
                    // TODO DPB allele functions and risks (note: allele could lead to benefit as well as risk)
                    /*
  word-break:break-all;

.snp-table .variant-table tr:first-child {
  border-top: none !important;
                    */
                    return (
                        <React.Fragment>
                            <tr key={"SNPs-" + nthSNP} className={"table-row"+ ((!rsIdInfos || (rsIdInfos.length === 0)) ? " table-row-bottom" : "")}>
                                <td>{categoryInfos.find(categoryInfo => categoryInfo.category === geneGroup) ? <button onMouseUp={() => showActions(geneGroup)} dangerouslySetInnerHTML={{__html: geneGroupHtml}}/> : <span dangerouslySetInnerHTML={{__html: geneGroupHtml}} />}</td>
                                <td className={"table-cell-gene-name"}>
                                    <span dangerouslySetInnerHTML={{__html: geneName + (geneNameContextualDiscriminator ? "  (" + geneNameContextualDiscriminator + ")" : "")}}/>
                                    {isOwner ? <button className={"button-edit-gene"} onClick={() => setShowEditGeneInterpretation(snpInterpretation)}>✎</button> : <span/>}
                                </td>
                                {/*<td className={"table-cell-snp-name"}><button onClick={() => setShowDetailsSNP(snpInfo)}>{snpName}{snpNameSupplement ? " (" + snpNameSupplement + ")" : ""}</button></td> */}

                                <td colSpan={3} className={"table-cell-info-text xcell_bottom_border"}>{geneSupplementAndAKA ? geneSupplementAndAKA.map((geneSupp, idx) =>
                                    <div key={"div-info-supp-" + idx} className={"div-info-text mild-emphasis"} dangerouslySetInnerHTML={{__html: geneSupp}}/>) : ""}{geneFunctions ? geneFunctions.map((snpFunction, fIdx) =>
                                    <div key={"div-info-function-" + fIdx} className={"div-info-text"} dangerouslySetInnerHTML={{__html: snpFunction}}/>) : ""}</td>
                                <td style={{width: "3.5em"}}></td>
                                <td colSpan={3} className={"table-cell-info-text xcell_bottom_border"}>{geneHealthRisks ? geneHealthRisks.map((risk, rIdx) =>
                                    <div key={"div-info-risk-" + rIdx} className={"div-info-text"} dangerouslySetInnerHTML={{__html: risk}}/>) : ""}</td>
                            </tr>
                            {rsIdInfos?.map((rsIdInfo, rsIdx) => {
                                const variant_name = rsIdInfo["variant_name"]
                                const rsId = rsIdInfo["rsId"] || "!!!!"
                                const myGenotype = rsIdInfo["my_genotype"] || "--"
                                const variantFunctions = highlightTextMatches(rsIdInfo["variant_functions"], bySelectors) // TODO DPB should display per variant
                                //const variantHealthRisks = highlightTextMatches(rsIdInfo["health_risks"], bySelectors) // TODO DPB should display per variant
                                const alleles = rsIdInfo["alleles"] || [{}]
                                return (
                                    <React.Fragment>
                                    <tr key={"SNPs" + nthSNP + "-rs-top-border-" + rsIdx} className={"table-row"}>
                                        <td></td>
                                        <td></td>
                                        <td colSpan={7} className={"table-row-rsid-separator"}></td>
                                    </tr>
                                    <tr key={"SNPs" + nthSNP + "-rs-" + rsIdx} className={"table-row"+ ((rsIdx === rsIdInfos.length - 1) ? " table-row-bottom" : "")}>
                                        <td></td>
                                        <td></td>
                                        {/*<td colSpan={2} className={"table-cell-variant-table"}>
                                    <table className={"variant-table"}>
                                        <thead>
                                        </thead>
                                        <tbody>
                                            return (<tr key={"SNPs-" + nthSNP + "-" + rsIdx} className={"variant-table-row"}>
                                                */}
                                        <td className={"table-cell-variant-name"}>{variant_name ? variant_name : "--"}</td>
                                        <td className={"table-cell-rsId"}>{rsId ? rsId : "-"}{hasVariantInterpretation(rsIdInfo) ? "(i)" : ""}</td>
                                        <td className={"table-cell-my-genotype"}>{myGenotype}</td>
                                        <td colSpan={4} className={"table-cell-allele-table"}>
                                            <table>
                                                <thead/>
                                                <tbody>
                                                {(variantFunctions && variantFunctions.length > 0) ? <tr key={"SNPs" + nthSNP + "-rs-" + rsIdx + "-functions"}>
                                                    <td colSpan={2} className={"table-cell-info-text"}><span
                                                        className={"variant-functions"}>{variantFunctions ? variantFunctions.map((risk, rIdx) =>
                                                        <div key={"SNPs" + nthSNP + "-rs-" + rsIdx + "-rule-" + rIdx} className={"div-info-text"}
                                                             dangerouslySetInnerHTML={{__html: risk}}/>) : ""}</span>
                                                    </td>
                                                </tr> : ""}
                                                {alleles.map((allele, alleleIdx) => {
                                                    const riskAllele = allele["risk_allele"]
                                                    const myStatus = allele["my_status"]
                                                    const alleleInterpretation = allele.risk_allele_interpretation;
                                                    const alleleFunctions = alleleInterpretation && highlightTextMatches(alleleInterpretation["functions"], bySelectors)
                                                    const alleleHealthRisks = alleleInterpretation && highlightTextMatches(alleleInterpretation["health_risks"], bySelectors)
                                                    const alleleHealthBoons = alleleInterpretation && highlightTextMatches(alleleInterpretation["health_boons"], bySelectors)
                                                    return (<React.Fragment>
                                                        {(alleleIdx > 0 || (variantFunctions && variantFunctions.length > 0))?
                                                            <tr key={"SNPs" + nthSNP + "-allele-top-border-" + alleleIdx} className={"table-row"}>
                                                                <td colSpan={3} className={"table-row-rsid-separator"}></td>
                                                            </tr> :
                                                            ""}
                                                        <tr key={"SNPs" + nthSNP + "-allele-" + alleleIdx} >
                                                            <td className={"table-cell-status-icon"}>
                                                                <span className={"my-status-icon"}>{forDisplay(myStatus)}</span>
                                                            </td>
                                                            <td className={"table-cell-allele"}>
                                                                <span className={"risk-allele"}>{riskAllele ? riskAllele : "--"}</span>
                                                            </td>
                                                            <td className={"table-cell-info-text"}><span
                                                                className={"allele-health-risks"}>{alleleFunctions ? alleleFunctions.map((alleleFunction, aIdx) =>
                                                                <div key={"SNPs" + nthSNP + "-allele-" + alleleIdx + "-d1-" + aIdx} className={"div-info-text mild-emphasis"}
                                                                     dangerouslySetInnerHTML={{__html: alleleFunction}}/>) : ""}{alleleHealthRisks ? alleleHealthRisks.map((risk, riskIdx) =>
                                                                <div key={"SNPs" + nthSNP + "-allele-" + alleleIdx + "-d2-" + riskIdx} className={"div-info-text"}
                                                                     dangerouslySetInnerHTML={{__html: risk}}/>) : ""}{alleleHealthBoons ? alleleHealthBoons.map((boon, boonIdx) =>
                                                                <div key={"SNPs" + nthSNP + "-allele-" + alleleIdx + "-d3-" + boonIdx} className={"div-info-text"}
                                                                     dangerouslySetInnerHTML={{__html: boon}}/>) : ""}</span>
                                                            </td>
                                                        </tr>
                                                    </React.Fragment>)
                                                })}
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                            </React.Fragment>)})}</React.Fragment>
                    )})}
            </tbody>
          </table>
        </span>)
}

export default AnnotatedSNPsTable;