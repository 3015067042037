import React, {useState} from "react";
import {RsIdsSelector, SnpGroupSelector, SnpInfoDetailsContains, SnpStartsWithSelector} from "../services/Selector";

/*
Search blobs

rs list results in interpretations and in genome lookups (Utility: select all genome lookups?; display interpretations as just genome lookups?)
Result should be numerically sorted

    always result in interpretations...

snp starts with

full text search of functions & health risks

Action is X[i] (like sorting on that column)
Test is T[i] (like sorting on that column) & homo (non-green)

----------------
Sort / aggregate

Functions/risks word cloud (but somehow actionable)

Action is X[i] (like sorting on that column)
Test is T[i] (like sorting on that column) & homo (non-green)

 */

const SnpsSearchForm = ({selectors, setSelectors, selectorCounts}) => {

    const [searchSnpNameStartsWith, setSearchSnpNameStartsWith] = useState("");
    const [searchRsId, setSearchRsId] = useState("");                              // TODO DPB rsId now has its own search form -- expunge from SnpsSearchForm
    const [searchSnpDetailsContains, setSearchSnpDetailsContains] = useState("")
    const [searchErrorMessages, setSearchErrorMessages] = useState([]);

    // TODO DPB [done?]  use subsumes... const alreadySelected = (selector) => selectors.find(exists => exists.sameAs(selector)); // TODO DPB PREVENT DUPLICATES

    const clearSelector = (selector) => {
        setSelectors((existing) => existing.filter(exists => exists !== selector));
    }

    const handleLookupSubmit = (e) => {
        e.preventDefault();
        let errorMessages = []
        // if both snp name  and rsId are filled in use both
        // for rsId if present in setSnpInterpretationsDict, then use the associated snp-name
        // else TODO DPB [ ] LOOK UP rsId in myGenomeDict and add that to the output SOMEHOW
        // TODO DPB if not found in snpInterpretations, then just include rsId and mygenome lookup in the ultimate output (how???)
        if (searchRsId && searchRsId !== '') {
            const newRsIdsSelector = new RsIdsSelector(searchRsId);
            setSelectors((existing) => [...existing, newRsIdsSelector])
            setSearchRsId("")
        }
        if (searchSnpNameStartsWith && searchSnpNameStartsWith !== '') {
            const newStartsWithSelection = new SnpStartsWithSelector(searchSnpNameStartsWith);
            setSelectors((existing) => existing?.find(startsWithSelection => startsWithSelection.subsumes(newStartsWithSelection)) ? existing : [...existing, newStartsWithSelection]);
            setSearchSnpNameStartsWith("")
        }
        if (searchSnpDetailsContains && searchSnpDetailsContains !== '') {
            const newDetailsContains = new SnpInfoDetailsContains(searchSnpDetailsContains);
            setSelectors((existing) => existing?.find(detailsContainsSelection => detailsContainsSelection.subsumes(newDetailsContains)) ? existing : [...existing, newDetailsContains])
            setSearchSnpDetailsContains("")
        }
        setSearchErrorMessages(errorMessages);
    };

    return <div className={"search-box"}><span className={"search-form"}>
        <form onSubmit={handleLookupSubmit}>
        Quick lookup:&nbsp;&nbsp;
            {/*<label key={"1"}>One or more rsIds<input
                name={"rsIdInput"}
                type={"text"}
                id={"rsIdInput"}
                value={searchRsId}
                maxLength={8120}
                minLength={2}
                onChange={(e) => {
                    setSearchErrorMessages([]);
                    setSearchRsId(e.target.value.toLowerCase());
                }}
            /></label>&nbsp;&nbsp;*/}&nbsp;&nbsp;<label key={"snpNameStartsWithInput"}>Gene Name starts with:<input
            name={"snpNameStartsWithInput"}
            type={"text"}
            id={"snpNameStartsWithInput"}
            value={searchSnpNameStartsWith}
            maxLength={63}
            minLength={2}
            onChange={(e) => {
                setSearchErrorMessages([]);
                setSearchSnpNameStartsWith(e.target.value.toUpperCase());
            }}
        /></label>&nbsp;&nbsp;&nbsp;&nbsp;<label key={"snpDetailsContains"}>Function/Risk contains:<input
            name={"snpDetailsContains"}
            type={"text"}
            id={"snpDetailsContains"}
            value={searchSnpDetailsContains}
            maxLength={63}
            minLength={2}
            onChange={(e) => {
                setSearchErrorMessages([]);
                setSearchSnpDetailsContains(e.target.value);
            }}
        /></label>
            <button key={"lookupSubmit"}
                disabled={(!searchRsId || searchRsId === '') &&
                    (!searchSnpNameStartsWith || searchSnpNameStartsWith === '') &&
                    (!searchSnpDetailsContains && searchSnpDetailsContains === '')}
                type={"submit"}>Lookup</button>
            {searchErrorMessages.map((searchErrorMessage, errorIdx) => <div key={"error" + errorIdx}
                className={"search-error"}>{searchErrorMessage}</div>)}
            <div key={"buttonPanel"} className={"button-panel"}>
                    <button name={"clear_lookup"}
                            disabled={!selectors || selectors.length === 0}
                            onMouseUp={() => {
                                setSelectors(() => []);
                                setSearchRsId("");
                                setSearchSnpNameStartsWith("");
                                setSearchSnpDetailsContains("");
                                setSearchErrorMessages([]);
                            }}>Clear lookups</button>
            </div>
      </form>
      <div>
         {selectorCounts?.map(({selector, filteredSNPsCount, filteredOutSNPsCount}) =>
             <span><button className={"lookup-lozenge"}
                           onMouseUp={() => clearSelector(selector)}>{filteredSNPsCount} {selector.displayAs()} {filteredOutSNPsCount ? "(" + filteredOutSNPsCount + " no genome) " : ""}<span className={"deleter"}>X</span></button></span>)}
      </div>
      </span>
    </div>
}

export default SnpsSearchForm