import {SNP_STATUS} from "../Constants";


function statusFor(myGenotype, allele, isBoon, typicalAllele, geneIdentifier) {
    // TODO DPB write unit tests
    // TODO DPB handle allele 'D' and allele "I" (and same in myGenotype)

    function isACGTDI(allele) {
        return allele === "A" || allele === "C" || allele === "G" || allele === "T" || allele === "D" || allele === "I";
    }

    let mySnpStatus;
    if (!myGenotype) {
        mySnpStatus = SNP_STATUS.NOT_GENOTYPED
    } else if (!allele || allele === "--") {
        mySnpStatus = SNP_STATUS.NO_INFO
    } else if (allele.length === 2) {
        if (myGenotype.length !==2) {
            // TODO DPB no way to model a match of a Y single genome to a single allele
            // not a match to this interpretation, even if X/Y Chromosome and this has Y, so only one in myGenotype?
            mySnpStatus = SNP_STATUS.WILD
        } else if (!isACGTDI(allele[0]) || !isACGTDI(allele[1])) {
            mySnpStatus = SNP_STATUS.BAD_INFO;
        } else if (allele === myGenotype || (allele[1] === myGenotype[0] && allele[0] === myGenotype[1])) {
            // both risk/boon allele and myGenotype are length 2 and matching/equal.
            // Example: DrRML Estrogen Metabolism:HSD17B1-8 and Muscle:ACTN3
            mySnpStatus = isBoon ? SNP_STATUS.HOMOZYGOUS_BOON : SNP_STATUS.HOMOZYGOUS_RISK
        } else {
            // 2 allele and 2 genome and not matching
            mySnpStatus = SNP_STATUS.WILD
        }
    } else if (!isACGTDI(allele)) {
        mySnpStatus = SNP_STATUS.BAD_INFO
    } else if (!myGenotype || myGenotype.length === 0 || myGenotype.length > 2) {
        mySnpStatus = SNP_STATUS.FOREIGN_GENOTYPE
    } else if (!isACGTDI(myGenotype[0])) {
        mySnpStatus = SNP_STATUS.FOREIGN_GENOTYPE
    } else if (myGenotype.length === 2 && !isACGTDI(myGenotype[1])) {
        mySnpStatus = SNP_STATUS.FOREIGN_GENOTYPE
    } else {
        // genotype is one or two characters of ACGT(DI)
        // now, look at the riskAllele
        if (allele.length !== 1) {
            if (allele === "" || allele === "--") {
                mySnpStatus = SNP_STATUS.NO_INFO
            } else {
                console.log(`!!!! Risk allele is not one or two characters: '${allele}' on ${geneIdentifier}`)
                mySnpStatus = SNP_STATUS.BAD_INFO
            }
        } else if (allele === typicalAllele) {
            console.log(`!!!! Risk allele on ${geneIdentifier} is same as typical allele: '${allele}'`)
            mySnpStatus = SNP_STATUS.BAD_INFO
        } else {
            if (myGenotype[0] === allele) {
                if (myGenotype.length === 2 && myGenotype[1] === allele) {
                    mySnpStatus = isBoon ? SNP_STATUS.HOMOZYGOUS_BOON : SNP_STATUS.HOMOZYGOUS_RISK
                } else {
                    mySnpStatus = isBoon ? SNP_STATUS.HETEROZYGOUS_BOON : SNP_STATUS.HETEROZYGOUS_RISK
                }
            } else {
                if (myGenotype.length === 2 && myGenotype[1] === allele) {
                    mySnpStatus = isBoon ? SNP_STATUS.HETEROZYGOUS_BOON : SNP_STATUS.HETEROZYGOUS_RISK
                } else {
                    // No matches to this risk allele [Note: this is not comparing to typical allele]
                    mySnpStatus = SNP_STATUS.WILD
                }
            }
        }
    }
    return mySnpStatus;
}

function annotationsForSNP(snpInterpretation, myGenome, extraInfo) {
    let snpListWithAnnotations = []
    let counts = [0, 0, 0, 0, 0]
    // TODO DPB update local storage and when running next time, initialize from local storage
    //
    const geneName = snpInterpretation["gene_name"] || snpInterpretation["snp_name"];
    const geneNameDiscriminator = snpInterpretation["gene_name_discriminator"];
    // TODO DPB use geneIdentifier
    const geneIdentifier = geneName + (geneNameDiscriminator ? "(" + geneNameDiscriminator + ")" : "");

    if (snpInterpretation) {
        let rsIds = snpInterpretation["rsIds"]
        const rsIdInfos = rsIds?.map((rsIdInterpretation) => {
            const myRsidInfo = {rsIdInterpretation}
            myRsidInfo["variant_name"] = rsIdInterpretation["variant_name"]
            const rsId = rsIdInterpretation["rsId"]?.toLowerCase()
            myRsidInfo["rsId"] = rsId
            const myGenotype = (myGenome && rsId) ? myGenome[rsId] : null
            myRsidInfo["my_genotype"] = myGenotype
            const typicalAllele = rsIdInterpretation["typical_allele"];
            const risk_alleles = rsIdInterpretation["risk_alleles"]
            /*if (DEBUG >= DEBUG_DETAIL) {
                console.log({"gene": geneName, "rsId": rsId, "my_genotype": myGenotype, "risk_alleles": risk_alleles})
            }*/
            let alleles = []
            if (risk_alleles) {
                // TODO DPB if I have one each of risk alleles, should not be yellow & yellow ?
                risk_alleles.forEach((riskAlleleInterpretation) => {
                    let riskAlleleRaw = riskAlleleInterpretation["allele"].toUpperCase()
                    riskAlleleRaw.split(',').map(allele => allele.trim()).forEach(riskAllele => {
                        const risks = riskAlleleInterpretation["health_risks"]
                        const boons = riskAlleleInterpretation["health_boons"]
                        const isBoon = !(risks && risks.length > 0) && (boons && boons.length >0)
                        let mySnpStatus = myGenome ?
                            statusFor(myGenotype, riskAllele, isBoon, typicalAllele, geneIdentifier) :
                            SNP_STATUS.WAITING;
                        const myRiskAlleleStatus = {
                            "risk_allele_interpretation": riskAlleleInterpretation,
                            "risk_allele": riskAllele,
                            "my_status": mySnpStatus
                        }
                        counts[mySnpStatus] += 1
                        alleles.push(myRiskAlleleStatus)
                    })
                })
            }
            myRsidInfo.alleles = alleles;
            return myRsidInfo;
        })
        const snpAnnotated = Object.assign({}, snpInterpretation, {"rsId_infos": rsIdInfos}, extraInfo)
        snpListWithAnnotations.push(snpAnnotated)
    } else {
        /*if (DEBUG >= DEBUG_DETAIL) {
            console.log("No interpretation for " + geneName)
        }*/
        const snpAnnotated = Object.assign({}, {"gene_name": geneName}, extraInfo)
        snpListWithAnnotations.push(snpAnnotated)
    }
    return [snpListWithAnnotations, counts]
}

const annotationsForSnpList = ({snps_lists, include_snp_groups}, snpInterpretationsDict, myGenomeDict) => {
    if (Object.keys(snpInterpretationsDict).length === 0) {
        return [];
    }
    let snpInterpretations = []
    snps_lists.forEach((snps_list) =>
        snps_list["SNPs"].forEach((geneNameWithDiscriminator) => {
                let snpInterpretation = snpInterpretationsDict[geneNameWithDiscriminator]
                if (snpInterpretation) {
                    snpInterpretations.push({snpInterpretation, snps_list})
                } else {
                    console.log("Expected to find snp interpretation for '" + geneNameWithDiscriminator +
                        "' in event's SNPs list named " + snps_list["snps_list_name"])
                    //alert("Expected to find snp interpretation for '" + geneNameWithDiscriminator + "'")
                }
            }
        ))
    let snpListAnnotations = []
    snpInterpretations.forEach(({snpInterpretation, snps_list}) => {
        const groupName = snpInterpretation["category_rml"] || snpInterpretation["group"];
        if (!include_snp_groups || include_snp_groups.includes(groupName)) {
            let extraInfo = {"snps_list_name": snps_list["snps_list_name"]}
            let [annotatedSnps] = annotationsForSNP(snpInterpretation, myGenomeDict, extraInfo)
            annotatedSnps.forEach((anno) => snpListAnnotations.push(anno))
        }
    })
    return snpListAnnotations
}

export default annotationsForSnpList