import React, {useState} from "react";

const RSIDS_COUNT_FITS = 5;

const leadingAlphas = (rsString) => {
    const match = rsString && rsString.match(/^[a-z]*/);
    return match ? match[0] : ""
}

const trailingNumber = (rsString) => {
    // TODO DPB likely more efficient to pad shorter numeric string with leading zeroes
    let trailingDigits = rsString.match(/[!0-9]*([0-9]+$)/);
    return trailingDigits ? parseInt(trailingDigits[0]) : 0;
}

const rsIdsEtcCompare = (a, b) => {
    // TODO DPB is it okay to assume [a-z]*[0-9]+ ?
    // sort by leading alphas and within that sort trailing digits numerically
    const leadingAlphasComparison = leadingAlphas(a.rsId).localeCompare(leadingAlphas(b.rsId))
    return leadingAlphasComparison !== 0 ? leadingAlphasComparison :
        trailingNumber(a.rsId) - (trailingNumber(b.rsId))
}

// Match columns / cells the way they are presented
const rsIdsTableHeaderKeys = ["rsId", "My Genome"]

const RsIdsTable = ({rsIdSelectionsList}) => {
    const [showAll, setShowAll] = useState(false)

    const toggleShowAll = () => {
        setShowAll(!showAll);
    }

    let foundRsIdsList = []
    rsIdSelectionsList.forEach(({selections}) => selections.forEach((selection) => {
        selection.rsId_infos?.forEach((rsIdInfo) =>
                foundRsIdsList.push(rsIdInfo))
     }))
    foundRsIdsList.sort(rsIdsEtcCompare)  // TODO DPB -- need to make this a numeric sort so rs99 comes before rs100
    let rowKeySuffix = 0;
    return <div>{
        rsIdSelectionsList.length === 0 ?
            <span/> :
            <div>
                {foundRsIdsList.length <= RSIDS_COUNT_FITS ? <span/> :
        <p className={"button-panel"}>
            <button name={"show_all_rsIds"} onMouseUp={toggleShowAll}>{showAll ? "Show fewer" : "Show All"}</button>
        </p>
                }
        <div className={"rsId-table-container" + (showAll || foundRsIdsList.length <= RSIDS_COUNT_FITS ? "" : " rsId-table-container-scroll")}>

                <table id="rsId-search-table-id" className={"rsId-table"}>
                    <thead>
                    <tr key={"header"}>
                        <th className={"rsId-table-name-th"}>{rsIdsTableHeaderKeys[0]}</th>
                        <th className={"rsId-table-genome-th"}>{rsIdsTableHeaderKeys[1]}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {foundRsIdsList.map(({rsId, my_genotype}) => {
                        let rowKey = (rsId ? rsId : "") + rowKeySuffix++
                        return <tr key={rowKey} className={"table-row"}>
                            <td className={"rsId-table-name-td"}>{rsId}</td>
                            <td className={"rsId-table-genome-td"}>{my_genotype ? my_genotype : "no data"}</td>
                        </tr>;
                    })}
                    </tbody>
                </table>
        </div>
        </div>
        }
    </div>
}

export default RsIdsTable;