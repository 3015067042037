import React from 'react';

const DonationComponent = ({donate_button_id, include_event_groups}) => {
    const payPalButtonHTML = `
<form action="https://www.paypal.com/donate" method="post" target="_top">
<input type="hidden" name="hosted_button_id" value="` + donate_button_id + `" />
<input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_SM.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
<img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
</form>
  `;
    const supplementalText = include_event_groups ? " (click \"Return to David Benjamin\" to see additional gene interpretations)" : "";

    return (
        <div>Please support future improvements in SNParrot.
            <div style={{ display: 'flex', justifyContent: 'start' }}><span dangerouslySetInnerHTML={{ __html: payPalButtonHTML }}/> any amount securely with PayPal{supplementalText}</div>
        </div>
    );
}

export default DonationComponent;
