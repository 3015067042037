import React, {useState} from "react";
import {RsIdsSelector, SnpGroupSelector, SnpInfoDetailsContains, SnpStartsWithSelector} from "../services/Selector";

/*
Search blobs

rs list results in interpretations and in genome lookups (Utility: select all genome lookups?; display interpretations as just genome lookups?)
Result should be numerically sorted

    always result in interpretations...

snp starts with

full text search of functions & health risks

Action is X[i] (like sorting on that column)
Test is T[i] (like sorting on that column) & homo (non-green)

----------------
Sort / aggregate

Functions/risks word cloud (but somehow actionable)

Action is X[i] (like sorting on that column)
Test is T[i] (like sorting on that column) & homo (non-green)

 */

const GroupsSelection = ({selectors, setGroupsFilter, groupFiltersCounts, allSnpInterpretationGroups}) => {

    const clearGroupsFilter = (selector) => {
        setGroupsFilter((existing) => existing.filter(exists => exists !== selector));
    }

    const handleGroupSubmit = (selectedSnpGroup) => {
        const newGroupSelector = new SnpGroupSelector(selectedSnpGroup);
        setGroupsFilter((existing) => existing?.find(groupSelector => groupSelector.subsumes(newGroupSelector)) ? existing : [...existing, newGroupSelector]);
    }

    return <div className={"groups-selection-box"}>
        { allSnpInterpretationGroups.length < 2 ? <span/> : <div className={"groups-selection-wrapper"}>Choose groups:&nbsp;&nbsp;
             <label key={"SnpGroupInput"}>Group:<select
            name={"SnpGroupInput"}
            id={"SnpGroupInput"}
            value={""}
            /*formLabel="Choose a service"
            buttonText="Send form"*/
            //defaultValue={{ label: "Click to see groups", value: "" }}
            //defaultValue={"Click to see groups"}
                 // TODO DPB change to a checkmarks drop-down!!!
            onChange={(e) => {
                //setSearchSnpGroup(e.target.value);
                handleGroupSubmit(e.target.value);
            }}>
            <option key={"see-groups"} value="Click to see groups" />
            {Array.from(allSnpInterpretationGroups).map(group =>
                <option key={"group-" + group} value={group}>{group}</option>)}
            </select></label>
            <div className={"button-panel"}>
                    <button name={"clear_groups"}
                            disabled={!selectors || selectors.length === 0}
                            onMouseUp={() => {
                                setGroupsFilter(() => []);
                            }}>Show all groups</button>
            </div></div>}
        <div>
                            {groupFiltersCounts?.map(({selector, filteredSNPsCount, filteredOutSNPsCount}) =>
                                <span><button className={"lookup-lozenge"}
                                              onMouseUp={() => clearGroupsFilter(selector)}>{filteredSNPsCount} {selector.displayAs()} {filteredOutSNPsCount ? "(" + filteredOutSNPsCount + " no genome) " : ""}<span className={"deleter"}>X</span></button></span>)}
                        </div>
      </div>
}

export default GroupsSelection