import React, {useState} from "react";
import ingestGenomeData from "../services/IngestGenome";
import {TRACE_INGEST} from "../Constants";
import JSZip from "jszip";

const SelectAndLoadGenomeDataFile = ({setGenomeDict}) => {

    const [genomeFile, setGenomeFile] = useState();
    const [genomeFileErrorMessage, setGenomeFileErrorMessage] = useState("");
    const [genomeIngestErrorMessages, setGenomeIngestErrorMessages] = useState([]);
    const [progressFeedback, setProgressFeedback] = useState(undefined);

    const fileReader = new FileReader();

    const unzip = (filename, fileContentsBuffer) => {
        if (filename.endsWith(".zip")) {
            /**/console.log(`Unzip genome ${filename}: unzip zippedString length...`)
            console.log(fileContentsBuffer.byteLength)/**/
            let zip = new JSZip();
            const zpromise = zip.loadAsync(fileContentsBuffer, {});
            return zpromise
                .then((zip) => {
                    // do something with the zip
                    const filenames = Object.keys(zip.files);
                    const genomeFilename = filenames.find((filename) => filename.endsWith(".txt") || filename.endsWith(".csv"));
                    /*console.log(jsonFilename);*/
                    const zipFile = zip.file(genomeFilename);
                    /*console.log(zipFile);*/
                    const promise = zipFile.async("string");
                    return promise;
                })
                .catch((error) => {
                        console.log("Error unzipping...");
                        console.log(error);
                        throw new Error("Error unzipping: " + error);
                    }
                )
        } else {
            let plainString = new TextDecoder().decode(fileContentsBuffer);
            let returnPromiseOutcome =  Promise.resolve(plainString);
            return returnPromiseOutcome;
        }
    }
    function ingestGenomeString(textOrZip, genomeFile) {
        setProgressFeedback("Processing data file named " + genomeFile.name);  // TODO DPB only show this if more than 1 second elapses
        // if a .zip file, then include unzip in read sequence
        let textPromise = unzip(genomeFile.name, textOrZip);
        Promise.all([textPromise]).then(([text]) => {
                if (TRACE_INGEST) {
                    console.log("Loaded genetic data file. Data size is " + text.length)
                }
                let [genomeDict, ingestErrors, ingestWarnings] = ingestGenomeData(text)
                let isGenomeLoaded = false;
                let freshEntriesCount = undefined;
                if (ingestErrors && ingestErrors.length > 0) {
                    genomeDict = {}
                    ingestErrors.push("This app is not able to be sure it accurately loaded your genome data, please contact support.")
                    ingestErrors.push("Attempted to process file named " + genomeFile.name)
                    setGenomeIngestErrorMessages(ingestErrors ? ingestErrors : [])
                    setProgressFeedback(undefined); // TODO DBP only set once per interaction to avoid non-determinism
                } else {
                    freshEntriesCount = genomeDict && Object.keys(genomeDict).length;
                    if (!freshEntriesCount) {
                        ingestErrors.push("That genome file did have any genome data this app could recognize, please contact support.")
                        ingestErrors.push("Attempted to process file named " + genomeFile.name)
                        setGenomeIngestErrorMessages(ingestErrors ? ingestErrors : [])
                        setProgressFeedback(undefined); // TODO DBP only set once per interaction to avoid non-determinism
                    } else {
                        isGenomeLoaded = true;
                        setProgressFeedback("Success.  Found " + freshEntriesCount + " entries in your genome file named " + genomeFile.name + ".");
                    }
                }
                setGenomeDict(Object.assign({}, {isLoaded: isGenomeLoaded, dict: genomeDict}));
            }
        )
    }

    const ingestGenomeFile = (genomeFile) => {
        if (!genomeFile) {
            if (TRACE_INGEST) {
                console.log("handleOnSubmit: file not defined")
            }
            setGenomeIngestErrorMessages(["Internal error: lost genome file name"])
            return;
        }
        fileReader.onload = function (event) {
            ingestGenomeString(fileReader.result, genomeFile);
        };
        if (TRACE_INGEST) {
            console.log("Reading file as genetic data...")
            console.log(genomeFile)
        }
        setProgressFeedback("Accessing data file...");  // TODO DPB only show this if more than 1 second elapses
        setGenomeDict(Object.assign({}, {isLoaded: false, dict: undefined}));   // be sure ref changes to trigger React update
        fileReader.readAsArrayBuffer(genomeFile);
    }

    const handleGenomeFileChoice = (e) => {
        e.preventDefault();
        let file = e.target.files[0];
        if (file.name !== genomeFile?.["name"]) {  // TODO DPB what if directory is distinct for new file compared to previous?
            // TODO DPB -- if a .zip file, then include unzip in read sequence
            const validFilename = file.name.endsWith(".txt") || file.name.endsWith(".zip") || file.name.endsWith(".csv");
            if (validFilename) {
                setGenomeFile(file);
                setGenomeFileErrorMessage( "");
                setGenomeIngestErrorMessages([]);  // TODO DBP only set once per interaction to avoid non-determinism
                // setProgressFeedback(undefined); // TODO DBP only set once per interaction to avoid non-determinism
                ingestGenomeFile(file);
            } else {
                setGenomeFileErrorMessage("Please choose a file ending in .txt.  Windows users: right-click and select 'extract' on 23andMe's .zip file. Mac users double click the .zip file");
                setGenomeIngestErrorMessages([]);  // TODO DBP only set once per interaction to avoid non-determinism
                setProgressFeedback(undefined); // TODO DBP only set once per interaction to avoid non-determinism
            }
        }
    };

    return <form>
        <div>
            <label key={"genesFileInput"}>Click/tap below and locate your downloaded genome data file that ends in '.zip' or '.txt'<br/>
                <input
                    type={"file"}
                    id={"genesFileInput"}
                    accept={".txt,.csv,.zip"}
                    onChange={handleGenomeFileChoice}
                /></label>
            <div className={"error-message"}>{genomeFileErrorMessage}</div>
        </div>
        <div className={progressFeedback ? "success-message" : "error-message"}>
            {progressFeedback ? progressFeedback /* TODO DPB add warnings */ :
                genomeFile ? genomeIngestErrorMessages.map(m => <div>{m}</div>) :
                    "Press the button to locate your genome data file"  // TODO DPB review this logic
            }
        </div>
    </form>
}

export default SelectAndLoadGenomeDataFile;
