import React, {useState} from "react";
import {RsIdsSelector} from "../services/Selector";

/*
Search blobs

rs list results in interpretations and in genome lookups (Utility: select all genome lookups?; display interpretations as just genome lookups?)
Result should be numerically sorted

    always result in interpretations...

snp starts with

full text search of functions & health risks

Action is X[i] (like sorting on that column)
Test is T[i] (like sorting on that column) & homo (non-green)

----------------
Sort / aggregate

Functions/risks word cloud (but somehow actionable)

Action is X[i] (like sorting on that column)
Test is T[i] (like sorting on that column) & homo (non-green)

 */

const RsIdsSearchForm = ({disabled, selectors, setSelectors, rsIdSelectionsList, genomeIsLoaded}) => {

    const [searchRsIdList, setSearchRsIdList] = useState("");
    const [searchErrorMessages, setSearchErrorMessages] = useState([]);

    // TODO DPB PREVENT DUPLICATES... const alreadySelected = (selector) => selectors.find(exists => exists.sameAs(selector));

    const clearRsIdSelection = (selector) => setSelectors((existing) => existing.filter(exists => exists !== selector));

    const handleLookupSubmit = (e) => {
        e.preventDefault();
        let errorMessages = []
        // if both snp name  and rsId are filled in use both
        // for rsId if present in setSnpInterpretationsDict, then use the associated snp-name
        // else TODO DPB [ ] LOOK UP rsId in myGenomeDict and add that to the output SOMEHOW
        // TODO DPB if not found in snpInterpretations, then just include rsId and myGenome lookup in the ultimate output (how???)
        // TODO DPB MAYBE... for individual rsIds... "No SNP interpretation found for '" + matchRsId + "' and it is not in your genome report"
        if (searchRsIdList && searchRsIdList !== '') {
            const newSelector = new RsIdsSelector(searchRsIdList);
            setSelectors((existing) => [...existing, newSelector])
            setSearchRsIdList("")
        }
        setSearchErrorMessages(errorMessages);
    };

    return <span className={"search-form"}>
        <form onSubmit={handleLookupSubmit}>
            <label key={"rsIds-list"}><span className={"textarea-label"} >Look up one or more rsIds</span>
                <textarea
                    name={"rsIdInput"}
                    id={"rsIdInput"}
                    disabled={disabled}
                    placeholder={"Enter one or more rsIds and press lookup"}
                    value={searchRsIdList}
                    maxLength={16000}
                    minLength={2}
                    cols={80}
                    rows={2}
                    onChange={(e) => {
                        setSearchErrorMessages([]);
                        setSearchRsIdList(e.target.value.toLowerCase());
                    }}
                /></label>
            <button key={"rsIds-lookup"}
                disabled={(!searchRsIdList || searchRsIdList === '')}
                type={"submit"}>Lookup</button>
            {searchErrorMessages.map((searchErrorMessage, index) => <div key={"rsIds-err-" + index}
                className={"search-error"}>{searchErrorMessage}</div>)}
            <div key={"rsIds-clear"} className={"button-panel"}>
                    <button name={"clear_lookup"}
                            disabled={!selectors || selectors.length === 0}
                            onMouseUp={() => {
                                setSelectors(() => []);
                                setSearchRsIdList("");
                                setSearchErrorMessages([]);
                            }}>Clear all</button>
                {rsIdSelectionsList?.map(({selector, selections}) => {
                    let thisSelectionGenomeCount = 0;
                    selections.forEach((selection) => {
                        selection.rsId_infos?.forEach((rsIdInfo) => {
                            if (rsIdInfo.my_genotype && rsIdInfo.my_genotype !== '??') {
                                thisSelectionGenomeCount++
                            }
                    })})
                    return <button className={"lookup-lozenge"} onMouseUp={() => clearRsIdSelection(selector)}>
                        {genomeIsLoaded ? (thisSelectionGenomeCount + "  in your"): "no"} data: {selector.displayAs(4)} <span className={"deleter"}>X</span>
                    </button>})}
                </div>
      </form>
    </span>
}

export default RsIdsSearchForm