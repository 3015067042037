import './App.css';
import React, {useState} from "react";
import annotationsForSnpList from "./services/AnnotateGenome";
import PassphraseForm from "./components/passphrasePage/PassphraseForm";
import SnpsSearchForm from "./components/SnpsSearchForm";
import RsIdsTable from "./components/RsIdsTable";
import AnnotatedSNPsTable from "./components/AnnotatedSNPsTable";
import selectFilterAndSortSnps from "./services/SelectFilterAndSortSnps";
import {eventInfo, practitionerDB} from "./services/PractitionerDB";
import SelectAndLoadGenomeDataFile from "./components/SelectAndLoadGenome";
import CopyrightAndDisclaimer from "./components/CopyrightAndDisclaimer";
import RsIdsSearchForm from "./components/RsIdsSearchForm";
import {INTRODUCTION, SNPS_GROUPS_FROM_URL_QUERY_PARAMETERS} from "./Constants";
import {SnpGroupSelector} from "./services/Selector";
import GroupsSelection from "./components/GroupsSelection";
import DonationComponent from "./components/DonationComponent";
//import ActionsAndTestsTable from "./components/ActionsAndTestsTable";
//import * as PropTypes from "prop-types";

import 'bootstrap/dist/css/bootstrap.min.css';  // from Chat4 output

const composeStats = (annotatedSnpList) => {
  let stats = {};
  stats["Gene interpretations"] = annotatedSnpList.length
  const countGenotypedSNPs = (geneInfo) => geneInfo.rsId_infos?.reduce((total,rsIdInfo) => (rsIdInfo["my_genotype"] ? total + 1 : total), 0)
  const genomeCount = annotatedSnpList?.reduce((total,geneInfo) => total + (countGenotypedSNPs(geneInfo) || 0), 0)
  const countNotGenotypedSNPs = (geneInfo) => geneInfo.rsId_infos?.reduce((total,rsIdInfo) => (rsIdInfo["my_genotype"] ? total : total + 1), 0)
  const noGenomeCount = annotatedSnpList?.reduce((total,geneInfo) => total + (countNotGenotypedSNPs(geneInfo) || 0), 0)
    if (genomeCount) {
        stats["SNPs with your status"] = genomeCount || "No genome entries.  Have you located your data file?";
        stats["SNPs without your status"] = noGenomeCount || "No genome entries.  Have you located your data file?"
    } else {
        stats["No genome data"] = "Have you located your data file?"
    }
  return stats;
}
/*

class CategoryInfosResearch extends React.Component {
    render() {
        return "category infos research here";
    }
}

CategoryInfosResearch.propTypes = {categoryInfos: PropTypes.arrayOf(PropTypes.any)};
*/

function App() {
  let INITIAL_GROUP_FILTERS = []
  if (SNPS_GROUPS_FROM_URL_QUERY_PARAMETERS && SNPS_GROUPS_FROM_URL_QUERY_PARAMETERS.length >= 1) {
      // TODO DPB move this to run when passphrase has just processed and verify the group exists
      SNPS_GROUPS_FROM_URL_QUERY_PARAMETERS.forEach(group => INITIAL_GROUP_FILTERS.push(new SnpGroupSelector(decodeURIComponent(group))))
  }

  const INITIAL_FILTERS = {groupsSelectors: INITIAL_GROUP_FILTERS, justMyGenomeViewFilter: false}
  const INITIAL_SELECTORS_FILTER_AND_SORT = {selectors: [], filters: INITIAL_FILTERS, sort: {alphaBySnpNameSort: false}}

  const [snpInterpretationsDict, setSnpInterpretationsDict] = useState({});
  const [categoryInfos, setCategoryInfos] = useState([]);
  const [myGenome, setMyGenome] = useState({isLoaded: false, dict: undefined});  // TODO DPB  do away with this -- just use it when read in
  const [rsIdSelectors, setRsIdSelectors] = useState([])
  const [selectorsFiltersAndSort, setSelectorsFiltersAndSort] = useState(INITIAL_SELECTORS_FILTER_AND_SORT)
  const [categoryInfosToShow, setCategoryInfosToShow] = useState(categoryInfos);
  const [showCategoryInfosResearch, setShowCategoryInfosResearch] = useState(false);

  const toggleMainSort = () => {
      const newSort = {sort: {alphaBySnpNameSort: !selectorsFiltersAndSort.sort["alphaBySnpNameSort"]}}; // TODO DPB if sort is singular, remove nesting to prevent ambiguity
      setSelectorsFiltersAndSort(Object.assign({}, selectorsFiltersAndSort, newSort));
  }
  const toggleJustMyGenomeFilter = () => {
      const newSetting = myGenome.isLoaded && !selectorsFiltersAndSort.filters["justMyGenomeViewFilter"]
      setSelectorsFiltersAndSort((existing) => { return {...existing, filters: {...existing.filters, justMyGenomeViewFilter: newSetting}}});   // , filters: }});
  }

  const toggleShowCategoryInfosResearch = () => {
      setShowCategoryInfosResearch(!showCategoryInfosResearch);
  }

  const setGroupsFilter = (groupsFilterSetter) => {
      setSelectorsFiltersAndSort((existing) => {
          return {...existing, filters: {...existing.filters, groupsSelectors: groupsFilterSetter(existing.filters.groupsSelectors)}}});
  }

  const setSelectors = (selectorsSetter) => {
      setSelectorsFiltersAndSort((existing) => {
          return {...selectorsFiltersAndSort, selectors: selectorsSetter(existing.selectors)}});
  }

  const rsIdSelectionsList = rsIdSelectors?.map((selector) => {
      return {selector, selections: selector.select(myGenome, [] /*snpListAnnotated*/)}});

  const snpListAnnotated = /*myGenome.isLoaded && */ annotationsForSnpList(eventInfo, snpInterpretationsDict, myGenome.dict);
  const stats = composeStats(snpListAnnotated);
  let {selectedFilteredAndSortedSNPs, groupFiltersCounts, selectorCounts} =
      selectFilterAndSortSnps(snpListAnnotated, selectorsFiltersAndSort, myGenome);

  const allSnpInterpretationGroups = [];
  snpListAnnotated.forEach(snpInterpretation => {
      const groupName = snpInterpretation["category_rml"] || snpInterpretation["group"];
      allSnpInterpretationGroups.includes(groupName) || allSnpInterpretationGroups.push(groupName);
  })

    const hideModalPopup = () => {
        document.getElementById("modal-popup").style.display = "none";
    }
    const showActions = (category) => {
        setCategoryInfosToShow(categoryInfos.filter(categoryInfo => categoryInfo.category === category));
        document.getElementById("modal-popup").style.display = "block";
    }

    return (
        <div className="App">
            <header className="App-header">
                <span className={"App-Title"}>SNParrot: Your Genome Talks to You</span>
            </header>
            {/*div for a popup with inline css to position over everything else and does not scroll with page and positioned below header. click anywhere to hide.  */}
           {/* <div id="modal-popup" style={{position: "fixed", top: "50px", left: "0px", width: "100%", height: "100%", background: "rgba(0,0,0,0.5)", zIndex: "10", display: "none"}}
            onMouseUp={hideModalPopup}>
                 this div  scrolls horizontally and vertically instead of the whole page.
                <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", background: "#fff", padding: "20px", borderRadius: "10px", overflow: "auto"}}>
                    <ActionsAndTestsTable selectedFilteredAndSortedSNPs={selectedFilteredAndSortedSNPs} categoryInfos={categoryInfosToShow}/>
                </div>
            </div>*/}


            <p>{INTRODUCTION}</p>
            <h1 className={"Section"}>Locate Your Genome Data</h1>
            <p className={"left-aligned-paragraph"}><i>Your data file is processed locally in this web browser and not transmitted anywhere.</i></p>
            <SelectAndLoadGenomeDataFile setGenomeDict={setMyGenome}/>
            <p/>
            <h1 className={"Section"}>Look Up Your RsIds</h1>
            {(!myGenome.isLoaded) ?
                <span className={"search-error"}>No genome data to look up rsIds - locate your genome data file</span> :
                <div>
                    <RsIdsSearchForm disabled={!myGenome.isLoaded}
                                     selectors={rsIdSelectors}
                                     setSelectors={setRsIdSelectors}
                                     rsIdSelectionsList={rsIdSelectionsList}
                                     genomeIsLoaded={myGenome.isLoaded}/>
                    <br/>
                    <RsIdsTable rsIdSelectionsList={rsIdSelectionsList}/>
                </div>
            }
            <p/><p/>
            <p>
            {eventInfo["donate_button_id"] ? <DonationComponent donate_button_id={eventInfo["donate_button_id"]} include_event_groups={eventInfo["include_snp_groups"]}/> : <span/> }
            </p>

            <h1 className={"Section"}>Genome Interpretation</h1>
            {(typeof snpInterpretationsDict !== 'object' || Object.keys(snpInterpretationsDict).length === 0) ?
                <div>
                    <PassphraseForm setSnpInterpretationsDict={setSnpInterpretationsDict} setCategoryInfos={setCategoryInfos}/>
                </div> :
                <div>
                    <div className={"Event-Title"}>{eventInfo["name"]}</div>
                    {eventInfo["theme"] ? <div className={"Event-Subtitle"} dangerouslySetInnerHTML={{ __html: eventInfo["theme"] }}></div> : <span/>}
                    Stats...
                    <div style={{display: "inline-block", verticalAlign: "top"}}>{Object.keys(stats)?.map((key, idx) =>
                        <div key={"div-" + idx} style={{display: "inline-block", verticalAlign: "top"}}>&nbsp;&nbsp;&nbsp;{key}: {stats[key]}</div>)}
                    </div>
                    <p/>
                    <GroupsSelection selectors={selectorsFiltersAndSort.filters.groupsSelectors}
                                     setGroupsFilter={setGroupsFilter}
                                     groupFiltersCounts={groupFiltersCounts}
                                     allSnpInterpretationGroups={allSnpInterpretationGroups}/>
                    <br/>
                    <SnpsSearchForm selectors={selectorsFiltersAndSort.selectors}
                                    setSelectors={setSelectors}
                                    selectorCounts={selectorCounts}/>
                    <br/>
                    <p className={"button-panel"}>
                        <button name={"sort_main"}
                                onMouseUp={toggleMainSort}>
                            {selectorsFiltersAndSort.sort["alphaBySnpNameSort"] ? "Original order" : "Sort by Gene Name"}
                        </button>
                        <button name={"filter_main"} disabled={!myGenome.isLoaded}
                                onMouseUp={toggleJustMyGenomeFilter}>
                            {selectorsFiltersAndSort.filters["justMyGenomeViewFilter"] ? "Show all" : "Show my genome"}
                        </button>
                        {/*<button name={"categoryInfosResearch"}
                                onMouseUp={toggleShowCategoryInfosResearch}>
                            {showCategoryInfosResearch ? "Hide CI Research" : "Show CI Research"}
                        </button>*/}
                    </p>


                   {/* <div>
                        <FilterAndSortOptions
                            sortOption={sortOption}
                            setSortOption={setSortOption}
                            showOption={showOption}
                            setShowOption={setShowOption}
                            researchOption={researchOption}
                            setResearchOption={setResearchOption}
                        />

                        <FilterAndSortOptions
                            sortOption={sortOption}
                            handleSortOptionChange={toggleMainSort}
                            showOption={showOption}
                            handleShowOptionChange={toggleJustMyGenomeFilter}
                            researchOption={researchOption}
                            handleResearchOptionChange={toggleShowCategoryInfosResearch}
                        />
                    </div>*/}

                    <p className={"left-aligned-paragraph"}>Printing hints: click the above buttons "Sort by Gene Name" and "Show my genome".  Consider saving as PDF</p>

                    {/*{showCategoryInfosResearch ?
                        <ActionsAndTestsTable selectedFilteredAndSortedSNPs={selectedFilteredAndSortedSNPs} categoryInfos={categoryInfos}/>
                        /*<CategoryInfosResearch categoryInfos={categoryInfos} />* / :
                        ""
                    }*/}
                    <AnnotatedSNPsTable selectedFilteredAndSortedSNPs={selectedFilteredAndSortedSNPs}
                                        showActions={showActions}
                                        categoryInfos={ [] /*set to enable:  categoryInfos*/} />
                </div>
            }
            <p/>
            <hr/>
            <h2>The Fine Print</h2>
            <CopyrightAndDisclaimer practitionerDB={practitionerDB}/>
            <p/>
            <span>SNParrot.com Copyright ©2022 David P. Benjamin, Los Angeles, California.  Preview Edition. Latest Features:</span>
            <table>
                <tbody>
                <tr key={"feature3"}>
                    <td>2023-01-11</td>
                    <td>Lookups appear as lozenges with counts; highlight match in SNP interpretation</td>
                </tr>
                <tr key={"feature2"}>
                    <td>2022-12-15</td>
                    <td>Lookup rsId's takes a list; inspects some data files from 23andMe, Ancestry, Strategene, SelfDecode</td>
                </tr>
                <tr key={"feature1"}>
                    <td>2022-11-13</td>
                    <td>Quick lookups; SNPs sort alphabetically; hide not-genotyped rows</td>
                </tr>
                </tbody>
            </table>
            <footer className={"footer"}>
                <div>For any issue or to provide feedback, <a href={"mailto:snparrot-support@dbenjamin.com?subject=SNParrot%20feedback%20v0.1"}>please click here to send email</a> or use this address snparrot-support@dbenjamin.com</div>
                <div>Developed by <a href={"https://www.linkedin.com/in/david-p-benjamin"}>David Benjamin</a></div>
                <div>UI/UX and Graphic design by <a href={"https://www.linkedin.com/in/dreia-f-melinkoff/"}>Dreia Melinkoff</a></div>
            </footer>
        </div>
  );
}

export default App;



