import React, { useState } from "react";
import processPassphrase from "../../services/passphrase/ProcessPassphrase";
import {PASSPHRASE_FROM_URL_QUERY_PARAMETERS} from "../../Constants";

const handleOnChangePassphrase = (event, passphrase, setSnpInterpretationsDict, setCategoryInfos, setPassphraseErrorMessage) => {
    event.preventDefault();
    event.stopPropagation();
    return processPassphrase(passphrase, setSnpInterpretationsDict, setCategoryInfos, setPassphraseErrorMessage);
}

const PassphraseForm = ({setSnpInterpretationsDict, setCategoryInfos}) => {
    const [passphrase, setPassphrase] = useState("");
    const [passphraseErrorMessage, setPassphraseErrorMessage] = useState("");
    if (PASSPHRASE_FROM_URL_QUERY_PARAMETERS) {
        const passphraseFromQuery = decodeURIComponent(PASSPHRASE_FROM_URL_QUERY_PARAMETERS).trim();
        // TODO DPB handle the promise returned... (prevent flash in the UI)
        processPassphrase(passphraseFromQuery, setSnpInterpretationsDict, setCategoryInfos, setPassphraseErrorMessage);
    }
    return <div>{passphraseErrorMessage
      ? <span className={"error-message"}>Please try a different passphrase. <span className={"error-details"}>Details: {passphraseErrorMessage}</span></span>
      : <span/>}
        <form onSubmit={(event) => handleOnChangePassphrase(event, passphrase, setSnpInterpretationsDict, setCategoryInfos, setPassphraseErrorMessage)}>
            <label>To access SNP interpretations, please enter the passphrase provided by your practitioner:<br/><input
                name={"passPhraseInput"}
                placeholder={"Enter passphrase here"}
                type={"text"}
                id={"passphraseInput"}
                value={passphrase}
                maxLength={255}
                minLength={20}
                size={70}
                onChange={(e) => {
                    setPassphraseErrorMessage("");
                    setPassphrase(e.target.value);
                }}
            /></label>
            <button
                type={"submit"}
                disabled={passphrase === ""}>submit</button>
            <p/>
        </form>
    </div>
}

export default PassphraseForm