
class Selector {
    constructor(props) {
    }
    highlightSnpGroup(text) {
        return text;
    }
    highlightSnpName(text) {
        return text;
    }
    highlightText(text) {
        return text;
    }
}

const matchRsIds = new RegExp('[A-Za-z]*\\d+', 'gmi');
const digitsOnly = new RegExp('^[0-9]+$');

const replaceAllKeepingCase = function(text, strReplace, strWith) {
    // From https://stackoverflow.com/a/7313467  (which cites http://stackoverflow.com/a/3561711/556609 )
    // From https://stackoverflow.com/a/19161971 : "Javascript vaja".replace(/(ja)/gi, '<b>$1</b>');
    if (!Array.isArray(text) && typeof text !== 'string' && !(text instanceof String)) {
        return text;
    }
    let esc = strReplace.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    let reg = new RegExp('(' + esc + ')', 'ig');
    return Array.isArray(text) ? text.map((txt) => txt.replace(reg, strWith)) : text.replace(reg, strWith);
};

const splitIntoIndividualRsIds = (inputString) => {
    let rsIds = [];
    let matches = inputString.matchAll(matchRsIds)
    for (const raw of matches) {
        let match = raw[0];
        // if digits only, prefix with 'rs'
        if (digitsOnly?.test(match)) {
            match = 'rs' + match;
        }
        rsIds.push(match);
    }
    return rsIds;
}

export class RsIdsSelector extends Selector {
    constructor(rsIdsString, ...props) {
        super(props);
        this.rsIds = splitIntoIndividualRsIds(rsIdsString); // TODO DPB maybe sort these (string sort or lexicographic sort?
    }
    displayAs(abbreviate = 100) {
        return this.rsIds.slice(0,abbreviate).join(" ") + (this.rsIds.length > abbreviate ? "... " : "");
    }
    select(genomeDict, snpListAnnotated) {
        let snpInfosForRsIds = []
        this.rsIds?.forEach((rsId) => {
            let snpInfosForRsId = snpListAnnotated.filter((snpInterpretation) =>
                snpInterpretation["rsId_infos"].some((rsIdInfo) => rsId === rsIdInfo["rsId"]))
            if (snpInfosForRsId.length === 0) {
                // Did not fine a gene interpretation (snpInfo) so construct a minimal gene interpretation
                snpInfosForRsId = [{
                    rsId_infos: [{rsId,
                        my_genotype: (genomeDict.isLoaded && genomeDict.dict[rsId]) || "??"}]
                }];
            }
            snpInfosForRsIds.push(...snpInfosForRsId)
        })
        return snpInfosForRsIds;
    }

    subsumes(other) {
        // every element of other is present in this.rsIds
        const otherRsIds = other && typeof other === 'object' && other["rsIds"];
        return otherRsIds && other.toString() === this.toString();  // TODO DPB needs more work; keep this.rsIds sorted to facilitate
    }
}

export class AllSelector extends Selector {
    constructor(startsWith, ...props) {
        super(props);
    }
    displayAs() {
        return '*';
    }
    select(genomeDict, snpListAnnotated) {
        return snpListAnnotated;
    }
    subsumes(other) {
        return true;
    }
}

export class SnpGroupSelector extends Selector {
    constructor(groupName, ...props) {
        super(props);
        this.groupName = groupName;
    }
    displayAs() {
        return this.groupName;
    }
    select(genomeDict, snpListAnnotated) {
        let snpInfosForGroupName = []
        if (this.groupName) {
            const groupName = this.groupName.toUpperCase()
            snpInfosForGroupName = snpListAnnotated.filter((snpInfo) =>
                (snpInfo["category_rml"] || snpInfo["group"])?.toUpperCase().startsWith(groupName)
            );
        }
        return snpInfosForGroupName;
    }
    highlightSnpGroup(text, subst) {
        return replaceAllKeepingCase(text, this.groupName, subst);
    }
    subsumes(other) {
        return other && typeof other === 'object' && other["groupName"]?.toUpperCase().startsWith(this.groupName.toUpperCase());
    }
}

export class SnpStartsWithSelector extends Selector {
    constructor(startsWith, ...props) {
        super(props);
        this.nameStartsWith = startsWith.toUpperCase();
    }
    displayAs() {
        return this.nameStartsWith + '*';
    }
    select(genomeDict, snpListAnnotated) {
        let snpInfosForStartsWith = []
        if (this.nameStartsWith) {
            snpInfosForStartsWith = snpListAnnotated.filter((snpInfo) =>
                (snpInfo["gene_name"] || snpInfo["snp_name"])?.split(" ").some(name => name.toUpperCase().startsWith(this.nameStartsWith))
            );
        }
        return snpInfosForStartsWith;
    }
    highlightSnpName(text, subst) {
        return replaceAllKeepingCase(text, this.nameStartsWith, subst);
    }
    subsumes(other) {
        return other && typeof other === 'object' && other["nameStartsWith"]?.startsWith(this.nameStartsWith);
    }
}

export class SnpInfoDetailsContains extends Selector {
    constructor(contains, ...props) {
        super(props);
        this.contains = contains.toUpperCase();
    }
    displayAs() {
        return '*' + this.contains + '*';
    }
    select(genomeDict, snpListAnnotated) {
        let snpInfosForDetailsContains = []
        if (this.contains) {
            let searchFor = this.contains.toUpperCase();
            snpInfosForDetailsContains = snpListAnnotated.filter((snpInfo) =>
                snpInfo["gene_functions"]?.some(text => text.toUpperCase().includes(searchFor)) ||
                snpInfo["snp_functions"]?.some(text => text.toUpperCase().includes(searchFor)) ||
                snpInfo["snp_health_risks"]?.some(text => text.toUpperCase().includes(searchFor)) ||
                snpInfo["rsIds"]?.some(rsId =>
                    rsId["variant_functions"]?.some(text => text.toUpperCase().includes(searchFor)) ||
                    rsId["risk_alleles"]?.some(allele =>
                        allele["health_risks"]?.some(text => text.toUpperCase().includes(searchFor)) ||
                        allele["health_boons"]?.some(text => text.toUpperCase().includes(searchFor)) ) )
            );
        }
        return snpInfosForDetailsContains;
    }
    highlightText(text, subst) {
        return replaceAllKeepingCase(text, this.contains, subst);
    }
    subsumes(other) {
        return other && typeof other === 'object' && other["contains"]?.startsWith(this.contains);
    }
}






