import {toUint8Array} from "./CryptFunctions";
// sunOpen LICENSE: CC0 License https://www.svgrepo.com/svg/307555/sun-shine
// sunFilled LICENSE: CC0 License https://www.svgrepo.com/svg/307556/sun-shining-sun-sunrise-shine
// cloudOpen LICENSE: CC0 License https://www.svgrepo.com/svg/307470/cloud-outstroke-weather-cloud-sky
// cloudFilled LICENSE: CC0 License https://www.svgrepo.com/svg/307466/cloud-weather
// okayHand public domain https://freesvg.org/ok-hand-sign
// hourglass public domain https://freesvg.org/simple-hourglass-vector-drawing
// edit (pencil) MIT https://www.svgrepo.com/svg/501541/edit
// unknown MIT https://www.svgrepo.com/svg/459142/unknown
import React from "react";
import { ReactComponent as SunOpenIcon } from './icons/sunOpen64.svg';
import { ReactComponent as SunFilledIcon } from './icons/sunFilled64.svg';
import { ReactComponent as CloudOpenIcon } from './icons/cloudOpen64.svg';
import { ReactComponent as CloudFilledIcon } from './icons/cloudFilled64.svg';
import { ReactComponent as OkayHandIcon } from './icons/okayHandSign.svg';
import { ReactComponent as HourglassIcon } from './icons/hourglass64.svg';
import { ReactComponent as EditIcon } from './icons/edit64.svg';
import { ReactComponent as UnknownIcon } from './icons/unknown64.svg';

export const VERSION_NO = "v0.1"

const DEBUG = 2    // TODO DPB derive current debug level from URL params
const DEBUG_DETAIL = 2
export const TRACE_INGEST = DEBUG >= DEBUG_DETAIL

/*

export const UTF8 = {
    "bright_sun": "🔆",
    "sun": "☼",
    "ok": "👌",
    "sun_and_clouds": "🌤",
    "black_cloud": "☁",
    "edit": "✍",
    "hourglass": "⏳"
}

export class SNP_STATUS_UTF8 {
    static HOMOZYGOUS_RISK = new SNP_STATUS("Homozygous risk", UTF8.black_cloud);
    static HETEROZYGOUS_RISK = new SNP_STATUS("Heterozygous risk", UTF8.black_cloud);
    static WILD = new SNP_STATUS("Wild", UTF8.ok);
    static HETEROZYGOUS_BOON = new SNP_STATUS("Heterozygous boon", UTF8.sun);
    static HOMOZYGOUS_BOON = new SNP_STATUS("Homozygous boon", UTF8.sun);
    static FOREIGN_GENOTYPE = new SNP_STATUS("@@");
    static NOT_GENOTYPED = new SNP_STATUS("??");
    static WAITING = new SNP_STATUS("Waiting", UTF8.hourglass);
    static NO_INFO = new SNP_STATUS("--");
    static BAD_INFO = new SNP_STATUS("!!");

    constructor(name, icon) {
        this.name = name;
        this.icon = icon || name;
        this.cssClass = name.toLowerCase().replace(/ /g, "_") + "_icon";
    }
}
*/

export const SVG_ICON = {
    "bright_sun": <SunFilledIcon className="genome-status-icon" />,
    "sun": <SunOpenIcon className="sun-status-icon" />,
    "ok": <OkayHandIcon className="genome-status-icon" />,
    "open_cloud": <CloudOpenIcon className="genome-status-icon" />,
    "black_cloud": <CloudFilledIcon className="genome-status-icon" />,
    "edit": <EditIcon className="genome-status-icon" />,
    "hourglass": <HourglassIcon className="hourglass-status-icon" />,
    "unknown": <UnknownIcon className="genome-status-icon" />
}

export class SNP_STATUS {
    static HOMOZYGOUS_RISK = new SNP_STATUS("homozygous risk", SVG_ICON.black_cloud);
    static HETEROZYGOUS_RISK = new SNP_STATUS("heterozygous risk", SVG_ICON.open_cloud);
    static WILD = new SNP_STATUS("Wild", SVG_ICON.ok);
    static HETEROZYGOUS_BOON = new SNP_STATUS("heterozygous protective", SVG_ICON.sun);
    static HOMOZYGOUS_BOON = new SNP_STATUS("homozygous protective", SVG_ICON.bright_sun);
    static FOREIGN_GENOTYPE = new SNP_STATUS("@@");
    static NOT_GENOTYPED = new SNP_STATUS("not genotyped", SVG_ICON.unknown);
    static WAITING = new SNP_STATUS("waiting for your data", SVG_ICON.hourglass);
    static NO_INFO = new SNP_STATUS("--");
    static BAD_INFO = new SNP_STATUS("!!");

    constructor(name, icon) {
        this.name = name;
        this.icon = icon || name;
        this.cssClass = name.toLowerCase().replace(/ /g, "_") + "_icon";
    }
}

export const INTRODUCTION = "SNParrot tells you what is in your genome data file.  Your practitioner may provide a passphrase for you to focus on your status for the SNPs being discussed during an event"

function getQueryVariable(variable)
{
    const query = window.location.search.substring(1);
    //console.log(query)//"app=article&act=news_content&aid=160990"
    const vars = query.split("&");
    //console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
    for (let i=0;i<vars.length;i++) {
        const pair = vars[i].split("=");
        //console.log(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ]
        if(pair[0] === variable){return pair[1];}
    }
    return false;
}

/* function to get query parameter from URL.  Support list-valued query parameters both ways */
export function getQueryParamList(paramName, url) {
    const urlParams = new URLSearchParams(url || window.location.search);
    const paramValue = urlParams.getAll(paramName);

    if (paramValue.length >= 1) {
        // The parameter has multiple values, return an array
        return paramValue;
    } else {
        // The parameter is not present in the URL
        return null;
    }
}

export const SNPS_GROUPS_FROM_URL_QUERY_PARAMETERS = getQueryParamList("group");

export const PASSPHRASE_FROM_URL_QUERY_PARAMETERS = getQueryVariable("passphrase");

export const EVENT_LOCATOR_FROM_URL_QUERY_PARAMETERS = getQueryVariable("event");

export const SNPS_ENC = {
    }

export const COPYRIGHT_DISCLAIMER_TEXT = "Disclaimer\n" +
    "The techniques and advice described on this website represent the opinions of the author based on her training and experience. The author expressly disclaims any responsibility for any liability, loss or risk, personal or otherwise, which is incurred as a result of using any of the techniques, recipes or recommendations suggested herein.\n" +
    "The responsibility for the consequences of your use of any suggestion or procedure described hereafter lies not with the authors, publisher or distributors of this book. This book is not intended as medical or health advice. If in any doubt, or if requiring medical advice, please contact the appropriate health professional. We recommend consulting with a licensed health professional before making major diet and lifestyle changes.\n" +
    "If you enjoy the information in this program, we would like to encourage you to show your support by sharing your testimonial with us, sharing our website with your friends and family, and supporting our continued work by considering our other programs and products.\n" +
    "Copyright © Dr. Ritamarie Loscalzo, MS, DC, CCN, DACBN All rights reserved.\n" +
    "Austin, Texas, USA.\n" +
    /*"\n" +
    "For issues with this web application, see the contact information at the bottom of the page.   For information about root-cause healthcare, contact Helpdesk@DrRitamarie.com\n"+*/
    "\n" +
    "No part of this publication may be reproduced, stored in a retrieval system, or transmitted in any form or by any means, whether electronic, mechanical, photocopying, recording or otherwise, without prior permission of the author. Please do not give away, publish on a website or in a newsletter or sell without permission of the author. You have permission to make as many printed or backup copies as you desire for personal use only. Thank you for respecting the hard work that went into creating this document for your education and enjoyment.\n" +
    "This edition dated October 18, 2022, was published in the United States of America by Dr. Ritamarie Loscalzo.\n"
const COPYRIGHT_AND_DISCLAIMER_HTML_GENERATOR = (practitionerDb) =>
    <span>
          Disclaimer
          <p/>
          The techniques and advice described ion this website represent the opinions of the author based on her training and experience. The author expressly disclaims any responsibility for any liability, loss or risk, personal or otherwise, which is incurred as a result of using any of the techniques, recipes or recommendations suggested herein.
          <p/>
          The responsibility for the consequences of your use of any suggestion or procedure described hereafter lies not with the authors, publisher or distributors of this book. This book is not intended as medical or health advice. If in any doubt, or if requiring medical advice, please contact the appropriate health professional. We recommend consulting with a licensed health professional before making major diet and lifestyle changes.
          <p/>
          If you enjoy the information in this program, we would like to encourage you to show your support by sharing your testimonial with us, sharing our website with your friends and family, and supporting our continued work by considering our other programs and products.
          <p>
          For issues with this web application, see the contact information at the bottom of the page.   For questions about {practitionerDb["friendly_offering"] || "our offerings"}, contact {practitionerDb["contact_email"] || (practitionerDb["friendly_practitioner"] + " at " + practitionerDb["friendly_organization"]) || " your provider"}
          </p>
          Copyright ©2022 {practitionerDb["full_practitioner"] || practitionerDb["friendly_practitioner"] || "David Benjamin"} All rights reserved. {practitionerDb["short_address"] || ""}.
          <p/>
          No part of this publication may be reproduced, stored in a retrieval system, or transmitted in any form or by any means, whether electronic, mechanical, photocopying, recording or otherwise, without prior permission of the author. Please do not give away, publish on a website or in a newsletter or sell without permission of the author. You have permission to make as many printed or backup copies as you desire for personal use only. Thank you for respecting the hard work that went into creating this document for your education and enjoyment.
          <p/>
          This edition dated July 23, 2022, was published in the United States of America by {practitionerDb["friendly_practitioner"] || practitionerDb["full_practitioner"] || "David Benjamin"}.
    </span>

const DR_BEN_LYNCH_PRACTITIONER_JSON = {
    "file_format": "SNParrot Practitioner",
    "version": "0.0.0",
    "updated": "2023-01-28",
    "updated_by": "David Benjamin",
    "friendly_practitioner": "Dr. Ben Lynch",
    "full_practitioner": "Dr. Ben Lynch",
    "friendly_organization": "Dr. Ben Lynch",
    "short_organization": "DBL",
    "short_address": "USA",
    "friendly_offering": "Dirty Genes Book and Strategene Genetics Report",
    "contact_email": "support@drbenlynch.com",
    "db_path": "/db/bl1",
    "practitioner_key": "Dr_Ben_Lynch",
    "salt_iv": toUint8Array("71,114,235,2,20,46,109,228,236,195,23,145"),
    "provider": {
        "name": "Dr. Ben Lynch",
        "email": "support@drbenlynch.com",
        "url": "https://www.drbenlynch.com/"
    },
    "copyright_disclaimer_text": COPYRIGHT_DISCLAIMER_TEXT,
    "copyright_disclaimer_html_generator": COPYRIGHT_AND_DISCLAIMER_HTML_GENERATOR,
    "programs": [{"name": "Dr. Ben Lynch Dirty Genes Book", "url": "https://www.drbenlynch.com/"}],
    "events": [
        {
            "locator": "DirtyGenes2018",
            "active_period": {"start": "2023-01-13T00:00:00Z", "end":  "2023-12-31T00:00:00Z"},
            "nick_name": "Dirty Genes 2018",
            "name": "Dr. Ben Lynch",
            "theme": "Dirty Genes Book 2018",
            "use_snps_lists": ["Super Seven"],
            "event_requires_login": true,
            "event_url": "https://www.drbenlynch.com/"
        }
    ],
    "snps_lists": {
        "Super Seven": {
            "SNPs": ["MTHFR (Energy)", "COMT (Energy)", "DAO", "MAOA (Mental Health)", "MAOA MAOB", "GST/GPX", "GSTM3", "GSTP1", "GPX", "NOS3", "PEMT"],
            "sources": [
                "Dirty Genes 2018"
            ]
        },
    },
    "snp_interpretation_sources": {
        "Dirty Genes 2018": {
            "path": "db/bl1",
            "file": "dirty_genes_v1_json.enc",
            "passphrase_index": 1,
            "IV_encryption_salt_index": 1
        }
    }
}

const STEPH_JACKSON_FFC_PRACTITIONER_JSON = {
    "file_format": "SNParrot Practitioner",
    "version": "0.0.0",
    "updated": "2023-08-11",
    "updated_by": "David Benjamin",
    "friendly_practitioner": "Steph Jackson",
    "full_practitioner": "Steph Jackson",
    "friendly_organization": "Friendly Flora Collective",
    "short_organization": "FFC",
    "short_address": "Canada",
    "friendly_offering": "Master Probiotics in the Kitchen",
    "contact_email": "steph@plantpoweredprobiotics.com",
    "db_path": "/db/sj1",
    "practitioner_key": "Steph_Jackson_Friendly",
    "salt_iv": toUint8Array("78,206,210,75,178,140,49,57,124,134,103,15"),
    "provider": {
        "name": "Steph Jackson",
        "email": "steph@plantpoweredprobiotics.com",
        "url": "https://www.friendlyfloracollective.com/"
    },
    "copyright_disclaimer_text": COPYRIGHT_DISCLAIMER_TEXT,
    "copyright_disclaimer_html_generator": COPYRIGHT_AND_DISCLAIMER_HTML_GENERATOR,
    "programs": [{"name": "Friendly Flora Collective", "url": "https://www.friendlyfloracollective.com/"}],
    "events": [
        //ffcGenetics202308.json
        {
            "locator": "ffcGeneticsSJ2023",
            "active_period": {"start": "2023-01-13T00:00:00Z", "end":  "2023-12-31T00:00:00Z"},
            "nick_name": "FFC November 2023",
            "name": "Steph Jackson's FFC Genetics Workshop",
            "theme": "November 2023 Histamine!",
            //"theme": "Aug 2023, Enzymes",
            //"theme": "July 2023, Zinc",
            //"theme": "June 2023, Magnesium",
            //"theme": "May 2023, Deficiencies",
            //"theme": "April 2023, Oxalates and Detox",
            "use_snps_lists": ["FFC Nov23", "FFC Oct23", "FFC Sep23", "FFC Aug23", "FFC Jul23", "FFC Jun23", "FFC May23", "FFC Apr23", "FFC Mar23", "FFC Feb23", "FFC Jan23", "FFC Oct22"],
            "event_requires_login": true,
            "event_url": "https://www.friendlyfloracollective.com/lessons/genetics-1/"
        },
        {
            "locator": "guthealthSJ2022",
            "active_period": {"start": "2022-10-26T00:00:00Z", "end":  "2023-12-31T00:00:00Z"},
            "nick_name": "FFC Fall 2022",
            "name": "Steph Jackson's Personalize Your Probiotics",
            "use_snps_lists": ["FFC Oct '22"],
            "event_requires_login": true,
            "event_url": "https://www.friendlyfloracollective.com/lessons/genetics-1/"
        }
    ],
    "snps_lists": {
        "FFC Oct22": {
            "SNPs": ["FOXO3", "CRHR1", "AOC1", "BACH2", "ITLN1", "IL10", "SLC22A4", "SLC22A5"],
            "sources": [
                "October22"
            ]
        },
        "FFC Jan23": {
            "SNPs": ["NOS2", "JAK2", "TNFSF15", "KLB", "VDR", "HTR3E"],
            "sources": [
                "January23"
            ]
        },
        "FFC Feb23": {
            "SNPs": ["IRGM", "NKX2-3", "AOX1", "TUBD1", "STAT3", "C5ORF56"],
            "sources": [
                "October22"
            ]
        },
        "FFC Mar23": {
            "SNPs": ["AGXT", "CASR (Oxalate)", "CLDN14", "DGKH", "GRHPR", "UMOD"],
            "sources": [
                "March23"
            ]
        },
        "FFC Apr23": {
            "SNPs": ["NR1 NR2", "PONI", "SLC25A1", "HOGA1", "SPP1"],
            "sources": [
                "April23"
            ]
        },
        "FFC May23": {
            "SNPs": ["GC", "PEMT", "SLC35F3", "SLC19A2", "SLC19A3"],
            "sources": [
                "May23"
            ]
        },
        "FFC Jun23": {
            "SNPs": ["VIPR1", "CSTA", "FGFR2", "DLK1", "SHROOM3", "MECOM", "RTL1", "THBS3", "PAPSS2"],
            "sources": [
                "June23"
            ]
        },
        "FFC Jul23": {
            "SNPs": ["SLC39A4", "SLC30A2", "SLC30A3", "SLC30A8", "ZNF365", "IL6"],
            "sources": [
                "July23"
            ]
        },
        "FFC Aug23": {
            "SNPs": ["PNLIP", "PNLIPRP1", "PEX7", "LCT", "TMPRSS15", "AMY1A", "PI3"],
            "sources": [
                "August23"
            ]
        },
        "FFC Sep23": {
            "SNPs": ["TF", "TMPRSS6", "SLC40A1", "CASR (Absorption)", "APOB", "ABCG8"],
            "sources": [
                "September23"
            ]
        },
        "FFC Oct23": {
            "SNPs": ["FABP2", "CYP7A1", "APOB (Oct23)", "FUT2", "PHACTR4", "UBE2G1", "PLN", "NR1H4"],
            "sources": [
                "October23"
            ]
        },
        "FFC Nov23": {
            "SNPs": ["HRH1", "HRH4", "NAT2", "HNMT", "MAOB", "MAOA", "ALDH1B1"],
            "sources": [
                "November23"
            ]
        }
    },
    "snp_interpretation_sources": {

        "October22": {
            "path": "db/sj1",
            "file": "ffcGenetics2022_json_zip.enc",
            "passphrase_index": 1,
            "IV_encryption_salt_index": 1
        },
        "January23": {
            "path": "db/sj1",
            "file": "ffcGenetics202301_json_zip.enc",
            "passphrase_index": 2,
            "IV_encryption_salt_index": 2
        },
        "February23": {
            "path": "db/sj1",
            "file": "ffcGenetics202302_json_zip.enc",
            "passphrase_index": 3,
            "IV_encryption_salt_index": 3
        },
        "March23": {
            "path": "db/sj1",
            "file": "ffcGenetics202303_json_zip.enc",
            "passphrase_index": 4,
            "IV_encryption_salt_index": 4
        },
        "April23": {
            "path": "db/sj1",
            "file": "ffcGenetics202304_json_zip.enc",
            "passphrase_index": 5,
            "IV_encryption_salt_index": 5
        },
        "May23": {
            "path": "db/sj1",
            "file": "ffcGenetics202305_json_zip.enc",
            "passphrase_index": 6,
            "IV_encryption_salt_index": 6
        },
        "June23": {
            "path": "db/sj1",
            "file": "ffcGenetics202306_json_zip.enc",
            "passphrase_index": 7,
            "IV_encryption_salt_index": 7
        },
        "July23": {
            "path": "db/sj1",
            "file": "ffcGenetics202307_json_zip.enc",
            "passphrase_index": 8,
            "IV_encryption_salt_index": 8
        },
        "August23": {
            "path": "db/sj1",
            "file": "ffcGenetics202308_json_zip.enc",
            "passphrase_index": 9,
            "IV_encryption_salt_index": 9
        },
        "September23": {
            "path": "db/sj1",
            "file": "ffcGenetics202309_json_zip.enc",
            "passphrase_index": 10,
            "IV_encryption_salt_index": 10
        },
        "October23": {
            "path": "db/sj1",
            "file": "ffcGenetics202310_json_zip.enc",
            "passphrase_index": 11,
            "IV_encryption_salt_index": 11
        },
        "November23": {
            "path": "db/sj1",
            "file": "ffcGenetics202311_histamine_json_zip.enc",
            "passphrase_index": 12,
            "IV_encryption_salt_index": 12
        }
    }
}
const DR_RITAMARIE_LOSCALZO_PRACTITIONER_JSON = {
    "file_format": "SNParrot Practitioner",
    "version": "0.0.1",
    "updated": "2023-10-18",
    "updated_by": "David Benjamin",
    "friendly_practitioner": "Dr. Ritamarie Loscalzo",
    "full_practitioner": "Dr. Ritamarie Loscalzo, MS, DC, CCN, DACBN",
    "friendly_organization": "Institute of Nutritional Endocrinology",
    "short_organization": "INE",
    "short_address": "Austin, Texas, USA",
    "friendly_offering": "Root Cause Health Care",
    "contact_email": "Helpdesk@DrRitamarie.com",
    "db_path": "/db/rl1",
    "practitioner_key": "Dr_Ritamarie_Loscalzo",
    "salt_iv": toUint8Array("97,30,233,17,188,248,199,102,61,94,126,203"),
    "practitioner": {
        "name": "Dr. Ritamarie Loscalzo",
        "email": "helpdesk@drritamarie.com",
        "url": "https://drritamarie.com"
    },
    "copyright_disclaimer_text": COPYRIGHT_DISCLAIMER_TEXT,
    "copyright_disclaimer_html_generator": COPYRIGHT_AND_DISCLAIMER_HTML_GENERATOR,
    "programs": [
        {"name": "Body FREEDOM Nutrition Lab", "url": "https://bodyfreedomnutritionlab.com/"},
        {"name": "Empowered Self-Care Lab", "url": "https://empoweredselfcarelab.com/"},
        {"name": "Energy Recharge Circle", "url": "https://unstoppablehealthcommunity.com/"}
    ],
    "events": [
        {
            "locator": "ERC_23to24",
            "active_period": {"start": "2024-01-01T00:00:00Z", "end":  "2024-03-30T23:59:00Z"},
            "nick_name": "ERC23",
            "name": "Dr. Ritamarie's Energy Recharge Circle, Class of 2023/2024",
            "theme": "<span style='color:green'>This web app is not endorsed/sponsored by Dr. Ritamarie.  See disclaimer below.<br/>Choose File above.  Your gene data does not leave your computer.</span>",
            "use_snps_lists": ["Digestion", "Misc", "BloodSugar", "NutrigenomicsJan23", "Energy", "Mitochondria"],
            "donate_button_id": "BTZXHY9F95U62",
            "event_requires_login": true,
            "event_url": "https://www.drritamarie.com/"
        },
        {
            "locator": "GNCVNovember23",
            "active_period": {"start": "2023-07-10T00:00:00Z", "end":  "2024-02-28T23:59:00Z"},
            "nick_name": "GNCV23",
            "name": "Dr. Ritamarie's SHINE, November 2023",
            "theme": "Genetics, Assessments, and Nutritional Strategies for Optimal Cardiovascular Function",
            "use_snps_lists": ["Digestion", "Misc", "BloodSugar", "NutrigenomicsJan23", "Energy", "Mitochondria"],
            "include_snp_groups": ["Autoimmune and Inflammation", "Blood Clotting",
                "Blood Pressure", "Nitric Oxide", "Triglyceride and Cholesterol", "Celiac Disease",
                "Digestion", "Vitamin and Mineral" /* nutrients */],
            "donate_button_id": "BTZXHY9F95U62",
            "event_requires_login": true,
            "event_url": "https://www.drritamarie.com/"
        },
        {
            "locator": "AllNewFirst",
            "active_period": {"start": "2023-01-10T00:00:00Z", "end":  "2023-12-31T23:59:00Z"},
            "nick_name": "GNCV23",
            "name": "Dr. Ritamarie's SHINE, November 2023",
            "theme": "Genetics, Assessments, and Nutritional Strategies for Optimal Cardiovascular Function",
            "use_snps_lists": ["Digestion", "Misc", "BloodSugar", "NutrigenomicsJan23", "Energy", "Mitochondria"],
            "include_snp_groups": ["Autoimmune and Inflammation", "Blood Clotting",
                "Blood Pressure", "Nitric Oxide", "Triglyceride and Cholesterol", "Celiac Disease",
                "Digestion", "Vitamin and Mineral" /* nutrients */],
            "donate_button_id": "BTZXHY9F95U62",
            "event_requires_login": true,
            "event_url": "https://www.drritamarie.com/"
        },
        {
            "locator": "GNCVNovember23Donors",
            "active_period": {"start": "2023-07-10T00:00:00Z", "end":  "2024-03-30T23:59:00Z"},
            "nick_name": "GNCV23",
            "name": "Dr. Ritamarie's SHINE, November 2023, Genetics, Assessments, and Nutritional Strategies for Optimal Cardiovascular Function",
            "theme": "<span style='color:green'>Thank you for your generous support of SNParrot!</span><br/>Bookmark this page to revisit the additional gene interpretations",
            "use_snps_lists": ["Digestion", "Misc", "BloodSugar", "NutrigenomicsJan23", "Energy", "Mitochondria"],
            "event_requires_login": true,
            "event_url": "https://www.drritamarie.com/"
        },
        {
            "locator": "GNCVNovember23Donor",
            "active_period": {"start": "2023-07-10T00:00:00Z", "end":  "2024-03-30T23:59:00Z"},
            "nick_name": "GNCV23",
            "name": "Dr. Ritamarie's SHINE, November 2023, Genetics, Assessments, and Nutritional Strategies for Optimal Cardiovascular Function",
            "theme": "<span style='color:green'>Thank you for your generous support of SNParrot!</span><br/>Bookmark this page to revisit the additional gene interpretations",
            "use_snps_lists": ["Digestion", "Misc", "BloodSugar", "NutrigenomicsJan23", "Energy", "Mitochondria"],
            "event_requires_login": true,
            "event_url": "https://www.drritamarie.com/"
        },
        {
            "locator": "GNCVNovember23VIP",
            "active_period": {"start": "2023-07-10T00:00:00Z", "end":  "2024-03-30T23:59:00Z"},
            "nick_name": "GNCV23",
            "name": "Dr. Ritamarie's SHINE, November 2023",
            "theme": "Genetics, Assessments, and Nutritional Strategies for Optimal Cardiovascular Function",
            "use_snps_lists": ["Digestion", "Misc", "BloodSugar", "NutrigenomicsJan23", "Energy", "Mitochondria"],
            "donate_button_id": "BTZXHY9F95U62",
            "event_requires_login": true,
            "event_url": "https://www.drritamarie.com/"
        },
        {
            "locator": "GDMJuly23",
            "active_period": {"start": "2023-07-10T00:00:00Z", "end":  "2023-08-30T23:59:00Z"},
            "nick_name": "GDM23",
            "name": "Dr. Ritamarie's Genes-Diet-Metabolism, July 2023",
            "theme": "Nutrigenomics",
            "use_snps_lists": ["Digestion", "Misc", "BloodSugar", "NutrigenomicsJan23", "Energy", "Mitochondria"],
            "include_snp_groups": ["Vitamin and Mineral", "Autoimmune and Inflammation", "Phase 1 Detoxification",
                "Mental Health – Mood, Stress, Sleep, Focus", "Blood Sugar Related", "Energy Metabolism",
                "Mitochondria", "Thyroid"],
            "donate_button_id": "BTZXHY9F95U62",
            "event_requires_login": true,
            "event_url": "https://www.drritamarie.com/"
        },
        {
            "locator": "GDMJuly23Donor",
            "active_period": {"start": "2023-07-10T00:00:00Z", "end":  "2023-08-30T23:59:00Z"},
            "nick_name": "GDM23",
            "name": "Dr. Ritamarie's Genes-Diet-Metabolism, July 2023",
            "theme": "<span style='color:green'>Thank you for your generous support of SNParrot!</span><br/>Bookmark this page to revisit the additional gene interpretations",
            "use_snps_lists": ["Digestion", "Misc", "BloodSugar", "NutrigenomicsJan23", "Energy", "Mitochondria"],
            "event_requires_login": true,
            "event_url": "https://www.drritamarie.com/"
        },
        {
            "locator": "GDMJuly23Donors",
            "active_period": {"start": "2023-07-10T00:00:00Z", "end":  "2023-08-30T23:59:00Z"},
            "nick_name": "GDM23",
            "name": "Dr. Ritamarie's Genes-Diet-Metabolism, July 2023",
            "theme": "<span style='color:green'>Thank you for your generous support of SNParrot!</span><br/>Bookmark this page to revisit the additional gene interpretations",
            "use_snps_lists": ["Digestion", "Misc", "BloodSugar", "NutrigenomicsJan23", "Energy", "Mitochondria"],
            "event_requires_login": true,
            "event_url": "https://www.drritamarie.com/"
        },
        {
            "locator": "NESJune23",
            "active_period": {"start": "2023-05-10T00:00:00Z", "end":  "2023-08-30T23:59:00Z"},
            "nick_name": "NES23",
            "name": "Dr. Ritamarie's Nutritional Endocrinology Symposium, June 2023",
            "theme": "Nutrigenomics",
            "use_snps_lists": ["Digestion", "Misc", "BloodSugar", "NutrigenomicsJan23", "Energy", "Mitochondria"],
            "event_requires_login": true,
            "event_url": "https://www.drritamarie.com/"
        },
        {
            "locator": "NESJune23FI",
            "active_period": {"start": "2023-05-10T00:00:00Z", "end":  "2023-08-30T23:59:00Z"},
            "nick_name": "NES23FI",
            "name": "Dr. Ritamarie's Nutritional Endocrinology Symposium, June 2023",
            "theme": "Focus on Food Intolerances",
            "use_snps_lists": ["FoodIntolerances"],
            "event_requires_login": true,
            "event_url": "https://www.drritamarie.com/"
        },
        {
            "locator": "NutrigenomicsJan23",
            "active_period": {"start": "2023-01-10T00:00:00Z", "end":  "2023-12-31T23:59:00Z"},
            "nick_name": "Nutrigenomics Jan23",
            "name": "Dr. Ritamarie's Nutrigenomics Workshop, January 2023",
            "theme": "Phase 1 Detox, Phase 2 Detox, Immune, Brain, Cardiovascular",
            "use_snps_lists": ["NutrigenomicsJan23", "Energy", "Mitochondria"],
            "event_requires_login": true,
            "event_url": "https://www.drritamarie.com/"
        },
        {
            "locator": "ShineFall2022",
            "active_period": {"start": "2022-10-26T00:00:00Z", "end": "2023-12-31T00:00:00Z"},
            "nick_name": "SHINE Fall 2022",
            "name": "SHINE 2022: Improve Chronic Fatigue Outcomes",
            "use_snps_lists": ["Energy", "Mitochondria", "ShineFall22"],
            "event_requires_login": true,
            "event_url": "https://www.shineliveconference.com/"
        },
        {
            "locator": "DrRitamariesSnpCharts",
            "active_period": {"start": "2022-10-26T00:00:00Z", "end": "2023-12-31T00:00:00Z"},
            "nick_name": "Dr. Ritamarie's SNP Charts",
            "name": "Dr. Ritamarie's SNP Charts Collection",
            "use_snps_lists": ["Energy", "Mitochondria", "ShineFall22"],
            "event_requires_login": true,
            "event_url": "https://www.drritamarie.com/"
        }/*,
        {
            "locator": "AllNewFirst",
            "active_period": {"start": "2023-01-10T00:00:00Z", "end":  "2023-12-31T23:59:00Z"},
            "nick_name": "New 23",
            "name": "All SNP Charts -- NEEDS PROOFREADING",
            "theme": "Likely matches SNP Charts; new features like per-rsId risk/boon",
            "use_snps_lists": ["Digestion", "Misc", "BloodSugar", "NutrigenomicsJan23", "Energy", "Mitochondria"],
            "event_requires_login": true,
            "event_url": "https://www.drritamarie.com/"
        }*/
    ],
    "snps_lists": {
        "FoodIntolerances": {
            "SNPs": [
                // "Gluten-free"
                "HLA (Energy)", "HLA (Celiac)", "HLA-DQ2 (Celiac)", "HLA-DQ2 (Digestion)",
                "HLA-DQA (Celiac)", "HLA-DQ8 (Celiac)", /* no data? */"HLA-DQ3", /* no data?*/"HLA-DQ7",
                "CTLA-4 (Celiac)", "SH2B3 (Celiac)",
                // "Dairy Free"
                "LCT"/* no data */, "MCM6" /*energy*/, "FLG/rs17616434", "FLG/rs2069772",
                // "Anti-yeast/fungal"
                "DEFB1"/* no data */, "FUT2",
                // "SCD, GAPS, FODMAPS"
                "FUT2", "AMY1"/* no data */,
                // "Low oxalate"
                "CASR" /* CaSR in SJ*/, "SPP1"/* in SJ*/, "AGXT"/* in SJ*/, "GRHPR/i5012629,i5012628"/* in SJ*/,
                // "Low salicylates"
                "MTHFR (Energy)", "MTHFR (Digestion)", "MTHFR (Blood Sugar)", "MTHFR (Thyroid)", "MTHFR", "CYP2C9", "UTG1A6"/* no data */,
                // "Low phenols"
                "SULT1A1", "SULT1A2"/* no data */,
                // "Low sulphur
                "CBS", "SULT"/* no data */
            ],
            "sources": [
                "energy", "mitochondria", "DrRitamarieMisc", "DrRitamarieDigestion",
                "DrRitamarieBloodSugar202303c", "shineFall22", "detoxWorkshopJan23"
            ]
        },
        "Energy": {
            "SNPs": [
                "ADRB2", "ADRA1A", "CRHR1", "NR3C1", "COMT (Energy)", "BDNF (Energy)", "SOD1", "SOD2", "SOD3",
                "MTHFR (Energy)", "MTRR (Energy)", "HFE (Energy)", "TF (Energy)", "TMPRSS6", "TNFα IL1b IL4 IL6",
                "HLA (Energy)", "MCM6", "SLC6A4", "HTR2A",
                "TH (Energy)", "GTP GCH", "GRIK2", "NPAS2", "TNF (Energy)", "IL6ST",
                "FTO", "MTOR", "AMPD1", "POMC", "TPH2", "MAOA MAOB"
            ],
            "sources": [
                "energy"
            ]
        },
        "Mitochondria": {
            "SNPs": [
                "UQCRC2", "NDUFS8", "NDUFS3", "NDUFS7", "COX6C", "COX5A", "ATP5FC1", "ATP5G3"
            ],
            "sources": [
                "mitochondria"
            ]
        },
        "Misc": {
            "SNPs": [
                "APOE (Alzheimer's)", "TOMM40", "ABCA2", "APBB2", "BHCE",
                "CLU", "TF (Alzheimer's)", "IDE", "INS-IGF2", "CCL2",
                "COL1A1", "COL5A1", "MMP3", "IL6 (Collagen/Muscle & Joint)",
                "CYP1A1 (Estrogen)", "CYP1A2 (Estrogen)", "CYP1B1 (Estrogen)",
                "CYP3A4 (Estrogen)", "HSD3", "ESR1", "SRD5A1", "SRD5A2", "CYP19A1",
                "SULT2A1", "SULT1E1", "STS", "AKR1C4", "CYP11B1", "CYP11B2", "HSD11B1",
                "BRCA1", "BRCA2", "HSD17B1-8", "COMT (Estrogen)", "SULT1A1", "SHBG",
                "BARD-1", "GSTM1 (Estrogen)", "PPARGC1A", "HIF1A", "ADAM12", "ACTN3"
            ],
            "sources": [
                "DrRitamarieMisc"
            ]
        },
        "Digestion": {
            "SNPs": [
                "MC4R", "DHCR7", "COMT (Digestion)", "CYP27B1",
                "VDR (Digestion)", "MTHFR (Digestion)", "HLA-DQ2 (Digestion)", "HLA-DQ8", "ATG16L1",
                "SHMT1", "FUT2 (Digestion)", "APOA4", "GIF", "PEMT (Digestion)", "ANKK1/DRD2", "LEPR",
                "FTO (Digestion)", "MAOA (Digestion)", "NBPF3 (Digestion)", "TAS2R38", "TAS1R3", "SLC2A2",
                "GAD1 (Digestion)", "LRRK2", "IgA (Digestion)", "GSTM3 (Digestion)", "CYP2E1 (Digestion)",
                "APOA2", "CYP2C19 (Digestion)", "MCM6 (Digestion)", "CCL26", "ADRB3",
                "PPARGC1A (Digestion)", /*"IRF5[P13]",*/ "IRF5", "GC (Digestion)", "APOE", "FADS1 (Digestion)",
                "FOX3", "CRHR1 (Digestion)", "ITLN1", "IL10",
                "SLC22A4 SLC22A5", "C5ORF56", "NOS2 (Digestion)", "IRGM", "JAK2", "AOX1", "TUBD1", "NOD2", "BACH2",
                "NLRP3", "STAT3 (Digestion)", "TNFSF15", "SI", "AOC1", "KLB", "HTR3E", "SLC35F3"

            ],
            "sources": [
                "DrRitamarieDigestion"
            ]
        },
        "BloodSugar": {
            "SNPs": [
                "MC4R (Blood Sugar)", "IGF1-R", "IRS1", "MTRR", "FTO (Blood Sugar)",
                "LEPR (Blood Sugar)", "SLC2A2 (Blood Sugar)", "MTHFR (Blood Sugar)",
                "ABCC8", "GLUT2", "GCGR", "PPARG", "TCF7L2", "ADIPOQ",
                "AKT2", "LIPC", "CAPN10", "GCK", "IL6R", "CRP", "IL18", "GCKR",
                "AFF1", "APOB", "APOA5-A4-C3-A1", "APOA5", "FADS1 FADS2 FADS3", "GALNT2",
                "LPL", "MLXIPL", "PLTP", "TRIB", "CLIP2", "ANGPTL3-DOCK7", "ADCY5",
                "ADRA2A", "CRY1 CRY2", "G6PC2", "GLIS3", "MADD", "MTNR1B", "PROX1"
            ],
            "sources": [
                "DrRitamarieBloodSugar202303c"
            ]
        },

        "ShineFall22": {
            "SNPs": [
                "DIO1", "DIO2", "PDE8B", "FOXE1", "CAPZB", "CTLA-4", "HLA-DRB1 (Thyroid)",
                "HLA-C", "STAT3", "PTPN22", "CD40", "FCRL3", "TSHR", "TPO", "BCM01 (Thyroid)",
                "MTHFR (Thyroid)", "MTR (Thyroid)", "MTRR (Thyroid)", "SH2B3", "TNF (Thyroid)", "VAV3",
                "BCM01", "NBPF3", "SLC30A8", "HFE", "TF", "VDR", "GC", "DHFR", "FOLR1 FOLR2 FOLR3",
                "TCN1 TCN2", "TRPM7", "NQ01",
                "CYP1A1", "CYP1A2", "CYP1B1", "CYP2A6", "CYP2C9",
                "CYP2C19", "CYP2D6", "CYP2E1", "CYP3A4",
                "GGH", "IFIH1", "4q27 region",
                "ATG16L", "TNF (Immune)", "CTLA4", "IL6", "LRP6", "FUT2", "TLR4"
            ],
            "sources": [
                "shineFall22"
            ]
        },
        "NutrigenomicsJan23": {
            "SNPs": [
                /* Phase 1 Detox */
                "CYP1A1", "CYP1A2", "CYP1B1", "CYP2A6", "CYP2C9",
                "CYP2C19", "CYP2D6", "CYP2E1", "CYP3A4",
                /* Phase 2 Detox Glutathione */ "GSR", "GSS", "GSTM1", "GSTM3", "GSTP1", "CTH",
                /* Phase 2 Detoxification Vitamin B9 Methylation */ "MTHFR", "MTHFD1L", "MTHFS",
                /* Autoimmune and Inflammation */
                "GGH", "IFIH1", "4q27 region",
                "ATG16L", "TNF (Immune)", "CTLA4", "IL6", "LRP6", "FUT2", "TLR4",
                /* Brain */ "BDNF", "PEMT", "FADS2",
                /* Triglyceride and Cholesterol */ "SLCO1B1", "APOC3", "CETP", "LDLR", "OLR1", "FADS1",
                /* Blood Pressure */ "ACE", "ADD1", "AGT", "TH (Blood Pressure)",
                /* Nitric Oxide */ "NOS1", "NOS2", "NOS3",
                /* Blood Clotting */ "Factor 5", "Factor 2", "Factor 3", "Factor 11", "CYP2C19 (Blood Clotting)", "GP6", "KNG1",
                /* Thyroid */
                "DIO1", "DIO2", "PDE8B", "FOXE1", "CAPZB", "CTLA-4", "HLA-DRB1 (Thyroid)",
                "HLA-C", "STAT3", "PTPN22", "CD40", "FCRL3", "TSHR", "TPO", "BCM01 (Thyroid)",
                "MTHFR (Thyroid)", "MTR (Thyroid)", "MTRR (Thyroid)", "SH2B3", "TNF (Thyroid)", "VAV3",
                /* Vitamin and Mineral */
                "BCM01", "NBPF3", "SLC30A8", "HFE", "TF", "VDR", "GC", "DHFR", "FOLR1 FOLR2 FOLR3",
                "TCN1 TCN2", "TRPM7", "NQ01",
                /* Celiac Disease */ "HLA (Celiac)", "HLA-DQA (Celiac)", "HLA-DQ2 (Celiac)", "HLA-DQ8 (Celiac)", "CTLA-4 (Celiac)", "SH2B3 (Celiac)",
                /* Cancer Related */ "BRCA1 (Cancer Related)", "BRCA2 (Cancer Related)", "RAD50 (Cancer Related)",
                "ADA (Cancer Related)", "CAT (Cancer Related)", "FOXE1 (Cancer Related)", "ESR2 (Cancer Related)",
                "FCGR2A (Cancer Related)", "NQ01 (Cancer Related)", "TRPM7 (Cancer Related)",
                /* Mental Health – Mood, Stress, Sleep, Focus */ "COMT (Mental Health)", "MAOA (Mental Health)",
                "MAOB (Mental Health)", "AANAT", "SLC6A2", "SLC6A3", "ANKK1", "DBH", "GAD1", "DRD1 DRD2 DRD3 DRD4"
            ],
            "sources": [
                "detoxWorkshopJan23",
                "shineFall22"
            ]
        },
        "DrRitamariesSnpCharts": {
            "SNPs": [
                /* Phase 1 Detox */
                "CYP1A1", "CYP1A2", "CYP1B1", "CYP2A6", "CYP2C9",
                "CYP2C19", "CYP2D6", "CYP2E1", "CYP3A4",
                /* Autoimmune and Inflammation */
                "GGH", "IFIH1", "4q27 region",
                "ATG16L", "TNF (Immune)", "CTLA4", "IL6", "LRP6", "FUT2", "TLR4",
                /* Thyroid */
                "DIO1", "DIO2", "PDE8B", "FOXE1", "CAPZB", "CTLA-4", "HLA-DRB1 (Thyroid)",
                "HLA-C", "STAT3", "PTPN22", "CD40", "FCRL3", "TSHR", "TPO", "BCM01 (Thyroid)",
                "MTHFR (Thyroid)", "MTR (Thyroid)", "MTRR (Thyroid)", "SH2B3", "TNF (Thyroid)", "VAV3",
                /* Vitamin and Mineral */
                "BCM01", "NBPF3", "SLC30A8", "HFE", "TF", "VDR", "GC", "DHFR", "FOLR1 FOLR2 FOLR3",
                "TCN1 TCN2", "TRPM7", "NQ01"
            ],
            "sources": [
                "shineFall22"
            ]
        }
    },
    "snp_interpretation_sources": {
        "energy": {
            "path": "db/rl1",
            "file": "energyRML2022b_json_zip.enc",
            "passphrase_index": 1,
            "IV_encryption_salt_index": 1
        },
        "mitochondria": {
            "path": "db/rl1",
            "file": "mitochondriaRML2022a_json_zip.enc",
            "passphrase_index": 2,
            "IV_encryption_salt_index": 2
        },
        "shineFall22": {
            "path": "db/rl1",
            "file": "shineFallRML2022a_json_zip.enc",
            "passphrase_index": 3,
            "IV_encryption_salt_index": 3
        },
        "detoxWorkshopJan23": {
            "path": "db/rl1",
            "file": "detoxWorkshopJan23_json_zip.enc",
            "passphrase_index": 4,
            "IV_encryption_salt_index": 4
        },
        "DrRitamarieMisc": {
            "path": "db/rl1",
            "file": "DrRitamarieMisc202401d_json_zip.enc",
            "passphrase_index": 5,
            "IV_encryption_salt_index": 5
        },
        "DrRitamarieDigestion": {
            "path": "db/rl1",
            "file": "DrRitamarieDigestion202401f_json_zip.enc",
            "passphrase_index": 6,
            "IV_encryption_salt_index": 6
        },
        "DrRitamarieBloodSugar202303c": {
            "path": "db/rl1",
            "file": "DrRitamarieBloodSugar202303c_json_zip.enc",
            "passphrase_index": 7,
            "IV_encryption_salt_index": 7
        }
    }
}

// Dr. Ritamarie's "SNPs Related Specific Food Intolerances" for NES2023
const NES2023_FOOD_INTOLERANCES_SNP_LIST = [
    {
        "group": "Gluten-free",
        "SNPs": ["HLA-DQ2", /*"HLA-DQ2.2", "HLA-DQ2.5", */ "HLA-DQ8", "HLA-DQA1", "HLA-DQ3", "HLA-DQ7", "CTLA4", "SH2B3"]
    },
    {"group": "Low salicylates", "SNPs": ["MTHFR", "CYP2C9", "UTG1A6"]},
    {"group": "SCD, GAPS, FODMAPS", "SNPs": ["FUT2", "AMY1"]},
    {"group": "Dairy Free", "SNPs": ["LCT", "MCM6", "FLG/rs17616434", "FLG/rs2069772"]},
    {"group": "Anti-yeast/fungal", "SNPs": ["DEFB1", "FUT2"]},
    {"group": "Low oxalate", "SNPs": ["CaSR", "SPP1", "AGXT", "GRHPR/i5012629", "GRHPR/i5012628"]},
    {"group": "Low phenols", "SNPs": ["SULT1A1", "SULT1A2"]},
    {"group": "Low sulphur", "SNPs": ["CBS", "SULT"]}
]

// Steph Jackson's Personalize Your Probiotics: Genetics and the Gut
const FFC_FUNCTIONAL_PROBITOICS_FFC_SNP_LIST = [
    {"group": "FFC Oct '22", "SNPs": ["FOX03", "CRHR1", "AOC1", "BACH2", "ITLN1", "IL10", "SLC22A4", "SLC22A5"]}
]
// Dr. Ritamarie's "SNPs Related Specific Food Avoidance" for SHINE'22
const SHINE_FALL2022_SNP_LIST = [
    {"group": "Mitochondria", "SNPs": ["UQCRC2", "NDUFS8", "NDUFS3", "NDUFS7", "COX6C", "COX5A", "ATP5FC1", "ATP5G3"]},
    {
        "group": "Energy",
        "SNPs": ["ADRB2", "ADRA1A", "CRHR1", "NR3C1", "COMT", "BDNF", "SOD1", "SOD2", "SOD3", "MTHFR", "MTRR",
            "HFE", "TF (transferrin)", "TMPRSS6", "Inflammation markers: TNFα IL1b IL4 IL6", "HLA-DQA1", "HLA-DRB1", "MCM6",
            "5-HTTLPR", "HTR2A", "TH", "GTP GCH", "GRIK2", "NPAS2", "TNF-308", "IL6ST", "FTO", "MTOR", "AMPD1", "POMC", "TPH2"]
    }
]
// Dr. Ritamarie's "SNPs Related Specific Food Avoidance" for SHINE'22
const PRE_SHINE_FALL2022_SNP_LIST = [
    {
        "group": "Gluten-free",
        "SNPs": ["HLA-DQ2", /*"HLA-DQ2.2", "HLA-DQ2.5", */ "HLA-DQ8", "HLA-DQA1", "HLA-DQ3", "HLA-DQ7"]
    },
    {"group": "Low salicylates", "SNPs": ["MTHFR", "CYP2C9", "UTG1A6"]},
    {"group": "SCD, GAPS, FODMAPS", "SNPs": ["FUT2", "AMY1"]},
    {"group": "Dairy Free", "SNPs": ["LCT", "MCM6", "FLG/rs17616434", "FLG/rs2069772"]},
    {"group": "Anti-yeast/fungal", "SNPs": ["DEFB1", "FUT2"]},
    {"group": "Low oxalate", "SNPs": ["CaSR", "SPP1", "AGXT", "GRHPR/i5012629", "GRHPR/i5012628"]},
    {"group": "Low phenols", "SNPs": ["SULT1A1", "SULT1A2"]},
    {"group": "Low sulphur", "SNPs": ["CBS", "SULT"]}
]

/*  TODO DPB use or lose
const SNPS_FOR_EVENTS = [
  {"key": "sj/guthealthSJ2022", "nick_name": "FFC Fall 2022", "name": "Steph Jackson's Personalize Your Probiotics", "snp_list": FFC_FUNCTIONAL_PROBITOICS_FFC_SNP_LIST},
  {"key": "rml/energyRML2022", "nick_name": "SHINE Fall 2022", "name": "Conference: Dr. Ritamarie's SNPs for SHINE Fall 2022", "snp_list": SHINE_FALL2022_SNP_LIST},
  {"nick_name": "PRE SHINE Fall 2022", "name": "Conference: Dr. Ritamarie's \"SNPs Related Specific Food Avoidance\" for SHINE'22", "snp_list": PRE_SHINE_FALL2022_SNP_LIST},
    // TODO DPB add an entry for 'custom' (paste a list) and change to a drop down to use if not implied/supplied by URL
]*/

export const PRACTITIONERS = [
    {"subdomain": "gdm23",
        "location_matcher": (location) => location.hostname.toLowerCase().startsWith("gdm23.") || location.pathname.toLowerCase().startsWith('/gdm23'),
        "db": DR_RITAMARIE_LOSCALZO_PRACTITIONER_JSON
    },
    {"subdomain": "gncv23",
        "location_matcher": (location) => location.hostname.toLowerCase().startsWith("gncv23.") || location.pathname.toLowerCase().startsWith('/gncv23'),
        "db": DR_RITAMARIE_LOSCALZO_PRACTITIONER_JSON
    },
    {"subdomain": "nes23",
        "location_matcher": (location) => location.hostname.toLowerCase().startsWith("nes23.") || location.pathname.toLowerCase().startsWith('/nes23'),
        "db": DR_RITAMARIE_LOSCALZO_PRACTITIONER_JSON
    },
    {"subdomain": "rml",
        "location_matcher": (location) => location.hostname.toLowerCase().startsWith("rml.") || location.pathname.toLowerCase().startsWith('/rml'),
        "db": DR_RITAMARIE_LOSCALZO_PRACTITIONER_JSON
    },
    {"subdomain": "ffc",
        "location_matcher": (location) => location.hostname.toLowerCase().startsWith("ffc.") || location.pathname.toLowerCase().startsWith('/ffc'),
        "db": STEPH_JACKSON_FFC_PRACTITIONER_JSON
    },
    {"subdomain": "dbl",
        "location_matcher": (location) => location.hostname.toLowerCase().startsWith("dbl.") || location.pathname.toLowerCase().startsWith('/dbl'),
        "db": DR_BEN_LYNCH_PRACTITIONER_JSON
    }
]
export const ACTIVE_PRACTITIONER_DB = DR_RITAMARIE_LOSCALZO_PRACTITIONER_JSON //STEPH_JACKSON_FFC_PRACTITIONER_JSON //
