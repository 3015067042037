import {postDecrypt, startDecryption} from "../../CryptFunctions";
import {eventInfo, practitionerDB} from "../PractitionerDB";
import JSZip from "jszip";
import {Base64} from 'js-base64';

const responseToTextBase64 =  (fetchResponse) => {
    if (!fetchResponse) throw new Error("fetchResponse is empty")
    let responseBase64 = fetchResponse.text()
    return responseBase64 // a Promise
}

const responseBinaryFromBase64 = (textResponseBase64) => {
    if (!textResponseBase64) {
        console.log("textResponseBase64 empty")
    }
    return Base64.decode(textResponseBase64);
}

const stringToJSON = (textResponse) => {
    if (!textResponse) {
        console.log("empty textResponse (failed decryption?)")
        throw new Error("empty textResponse (failed decryption?)")
    }
    let jsonResponse = JSON.parse(textResponse)
    if (!jsonResponse) {
        throw new Error("empty jsonResponse (incorrect decryption?)")
    }
    return jsonResponse
}

const composeSnpInterpretation = (fetchedSnpInterpretation) => {
    if (!fetchedSnpInterpretation) {
        throw new Error("empty information download")
    }
    const snpInterpretationListAdditions = fetchedSnpInterpretation["snp_science"]
    const categoryInfos = fetchedSnpInterpretation["category_infos"]
    return ({snpInterpretationListAdditions, categoryInfos})
}

const unzip = (filename, zippedString) => {
    /**/console.log(`decrypt ${filename}: unzip zippedString length...`)
    console.log(zippedString.byteLength)/**/
    if (filename.endsWith("_json_zip.enc")) {
        let zip = new JSZip();
        const zpromise = zip.loadAsync(zippedString, {});
        return zpromise
            .then((zip) => {
                // do something with the zip
                const jsonFilename = filename.replace("_json_zip.enc", ".json");
                /*console.log(jsonFilename);*/
                const zipFile = zip.file(jsonFilename);
                /*console.log(zipFile);*/
                const promise = zipFile.async("string");
                return promise;
            })
            .catch((error) => {
                    console.log("Error unzipping...");
                    console.log(error);
                    throw new Error("Error unzipping: " + error);
                }
            )
    } else {
        return postDecrypt(zippedString);
    }
}

const fetchInterpretation = async (snp_interpretation_source, practitionerVault, setPassphraseErrorMessage) => {
    const filename = snp_interpretation_source.file;
    const interpretation_path = `${snp_interpretation_source["path"]}/${filename}`;
    let phase
    try {
        phase = ""
        const headers = { 'Content-Type': 'text/plain' };
        const response = await fetch(`${process.env.PUBLIC_URL}/${interpretation_path}`, { headers });
        phase = "convert to base64"
        const base64 = await responseToTextBase64(response);
        phase = "convert to binary"
        const cryptoText = responseBinaryFromBase64(base64);
        phase = "decrypt"
        let iPassphrase = practitionerVault["vault"]["passphrases"][snp_interpretation_source.passphrase_index];
        let iSalt = new Uint8Array(practitionerVault["vault"]["salts"][snp_interpretation_source.IV_encryption_salt_index]);
        const jsonStringPossiblyZipped = await startDecryption(cryptoText, iPassphrase, iSalt);
        phase = "possibly unzip"
        const jsonString = await unzip(filename, jsonStringPossiblyZipped);
        phase = "convert to JSON"
        const interpretationJson = await stringToJSON(jsonString);
        phase = "compose"
        const { snpInterpretationListAdditions, categoryInfos } = await composeSnpInterpretation(interpretationJson);
        console.log(`Received ${snpInterpretationListAdditions?.length} gene interpretations and ${categoryInfos?.length} category infos`);
        return { snpInterpretationListAdditions, categoryInfos };
    } catch (error) {
        setPassphraseErrorMessage("Gene interpretations from " + interpretation_path + " failed to " + phase + " because " + error);
    }
}

const snpInterpretationAsDict = (snpInterpretationAsList) => {
    let newSnpSInterpretationDict = {}
    snpInterpretationAsList.forEach((snp) => {
        const geneIdentifier = snp["snp_name"] || snp["gene_name"];
        const geneContextualDiscriminator = snp["gene_name_discriminator"];
        const geneContextualIdentifier = geneContextualDiscriminator ? geneIdentifier + " (" + geneContextualDiscriminator + ")" : geneIdentifier;
        newSnpSInterpretationDict[geneContextualIdentifier] = snp
    })
    return newSnpSInterpretationDict
}

const extractSourceInfos = (practitionerDB, snpGroupsForEvent) => {
    let sourceNames = [];
    snpGroupsForEvent.forEach((snpGroup) =>
        snpGroup.sources.forEach((source) =>
            sourceNames.includes(source) ? null : sourceNames.push(source)));
    let sourceInfos = sourceNames.map((name) => practitionerDB.snp_interpretation_sources[name]);
    return sourceInfos;
}

const handlePractitionerVault = (practitionerVault, setSnpInterpretationsDict, setCategoryInfos, setPassphraseErrorMessage) => {
    if (!practitionerVault) {
        console.log("Empty practitionerVault")
    } /*else {
        console.log("Practitioner vault...")
        console.log(practitionerVault);
    }*/
    let sourceInfos = extractSourceInfos(practitionerDB, eventInfo["snps_lists"])
    let promises = sourceInfos.map((info) =>
        fetchInterpretation(info, practitionerVault, setPassphraseErrorMessage)/* returns Promise */)
    return Promise.all(promises).then((snpInterpretationsListOfLists) =>{
        let snpInterpretationListWhole = []
        let categoryInfosAll = []
        snpInterpretationsListOfLists.forEach(({snpInterpretationListAdditions, categoryInfos}) => {
            snpInterpretationListWhole = [...snpInterpretationListWhole, ...(snpInterpretationListAdditions || [])]
            categoryInfosAll = [...categoryInfosAll, ...(categoryInfos || [])]
        })
        let newSnpInterpretationDict = snpInterpretationAsDict(snpInterpretationListWhole);
        setSnpInterpretationsDict(newSnpInterpretationDict)
        setCategoryInfos(categoryInfosAll)
    });
}

async function processPassphrase(passphrase, setSnpInterpretationsDict, setCategoryInfos, setPassphraseErrorMessage) {
    let phase;
    try {
        phase = "compose vault filename"
        const filename = practitionerDB.practitioner_key + "_vault_json_zip.enc";
        phase = "compose vault url"
        const vault_url = process.env.PUBLIC_URL + practitionerDB.db_path + "/" + filename;
        phase = "fetch vault"
        const headers = { 'Content-Type': 'text/plain' }
        const fetchResponse = await fetch(vault_url, { headers });
        phase = "convert vault to base64"
        const base64 = await responseToTextBase64(fetchResponse);
        phase = "convert base64 to binary"
        const cryptoText = responseBinaryFromBase64(base64);
        phase = "decrypt vault"
        const jsonStringPossiblyZipped = await startDecryption(cryptoText, passphrase.trim(), practitionerDB.salt_iv);
        phase = "unzip vault"
        const jsonString = await unzip(filename, jsonStringPossiblyZipped);
        phase = "convert vault to JSON"
        const vaultJson = await stringToJSON(jsonString);
        phase = "handle vault"
        await handlePractitionerVault(vaultJson, setSnpInterpretationsDict, setCategoryInfos, setPassphraseErrorMessage);
        console.log("Done caching vault and interpretations");
    } catch (error) {
        setPassphraseErrorMessage("Failed to " + phase + " because " + error);
    }
}

export default processPassphrase;