import {EVENT_LOCATOR_FROM_URL_QUERY_PARAMETERS, PRACTITIONERS} from "../Constants";

// If URL indicates a different practitioner, use that
const foundPractitioner = PRACTITIONERS.find((practitioner) => {
    const matcher = practitioner["location_matcher"];
    return matcher && matcher(window.location);
})

const ACTIVE_PRACTITIONER_DB = (foundPractitioner || PRACTITIONERS[0])["db"] // DR_RITAMARIE_LOSCALZO_PRACTITIONER_JSON //STEPH_JACKSON_FFC_PRACTITIONER_JSON //

export let practitionerDB = Object.assign({}, {status: "loaded"}, ACTIVE_PRACTITIONER_DB)

const chosenEvent = EVENT_LOCATOR_FROM_URL_QUERY_PARAMETERS;
const eventData = practitionerDB.events.find(evt => evt.locator === chosenEvent);
const event = eventData || practitionerDB.events[0];
const snpsListsForEvent = event.use_snps_lists.map((snps_list_name) => {
    let snpsList = practitionerDB.snps_lists[snps_list_name];
    if (!snpsList) {
        console.log("Expected to find snps_list named " + snps_list_name + " in practitioner DB for event " + event.locator);
    }
    return {...snpsList, snps_list_name};
});

export let eventInfo = Object.assign({}, event, {"snps_lists": snpsListsForEvent},);

